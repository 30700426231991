import { useContext, useEffect, useState } from "react";
import FiltrosDefault from "../FiltrosDefault";
import Organizacao from "../../middleware/organizacao";
import Perfil from "../../middleware/perfil";
import { ListaDeProcessoSeletivo } from "../../interfaces/processoSeletivo";
import { toast } from "react-toastify";
import { listarProcessoSeletivo } from "../../services/processosSeletivo";
import NenhumResultadoEncontrado from "../NenhumResultadoEncontrato";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import ModalProcessoNps from "../ModalProcessoNps";
import "./nps.css";

function NpsProcessoSeletivo() {
  const { modal, modalExibir, atualizarInformacoes } = useContext(ModalEstadoContext);
  const [termo, setTermo] = useState("")
  const [situacaoProcesso, setSituacaoProcesso] = useState("")

  const [processando, setProcessando] = useState(false)
  const [nenhumResultado, setNenhumResultado] = useState(false)

  const [processosSeletivosFiltrado, setProcessosSeletivosFiltrado] = useState<ListaDeProcessoSeletivo[]>([])
  const [processoSeletivoSelecionado, setProcessoSeletivoSelecionado] = useState<ListaDeProcessoSeletivo>()

  const listarProcesso = async () => {
    setProcessando(true)
    setNenhumResultado(false)

    try {
      const processoSeletivoListado = await toast.promise(
        listarProcessoSeletivo(),
        {
          pending: 'Processando a sua Solicitação. Por favor, aguarde!'
        }
      )

      const processoFiltrado = processoSeletivoListado.data.filter((processo: any) => processo.situacao === "nps")

      setProcessosSeletivosFiltrado(processoFiltrado)

      if (processoFiltrado.length === 0) setNenhumResultado(true)
      setProcessando(false)

    } catch (error) {
      toast.error('Falha ao Listar Processos Seletivos!')
      setNenhumResultado(true)
      setProcessando(false)
    }
  }

  const AbrirModalNpsProcessoSeletivo = async (processo: ListaDeProcessoSeletivo) => {
    setProcessoSeletivoSelecionado(processo);
    return modalExibir("ModalNps")
  }

  useEffect(() => {
    listarProcesso()
  }, [atualizarInformacoes])

  return (
    <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
      <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor']}>
        <div id='paginaContainer'>
          <FiltrosDefault termo={termo} situacao={situacaoProcesso} placeholder='Pesquise pelo nome fantasia do Cliente' tipo={""} onChangeTermo={(e) => { setTermo(e.target.value) }} onChangeSituacao={(e) => { setSituacaoProcesso(e.target.value) }} onSubmit={(e) => { }} listaSituacao={[{ nome: 'Todos', valor: '' }]} genero='masculino' isLoading={processando} />
          {nenhumResultado && <NenhumResultadoEncontrado />}
          <section>
            <table id="tabelaListaContainer">
              <thead>
                <tr>
                  <th align='left'>Cliente</th>
                  <th align='left' className='hide-responsive'>Cargo</th>
                  <th align='center' className='hide-responsive'>Regime</th>
                  <th align='center' className='hide-responsive'>Responsável</th>
                </tr>
              </thead>
              <tbody>
                {processosSeletivosFiltrado.map((processo) => (
                  <tr key={processo.id} onClick={() => { AbrirModalNpsProcessoSeletivo(processo) }}>
                    <td className='primeiraColuna' align="left">{processo.cadastro.nomeFantasia}</td>
                    <td align='left' className='hide-responsive'>{processo.titulo}</td>
                    <td align='center' className='hide-responsive'>{processo.regimeContratacao}</td>
                    <td align='center' className='hide-responsive'>{processo.usuario.nome}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
          {modal.nome === "ModalNps" && processoSeletivoSelecionado &&
            <ModalProcessoNps processoSeletivoSelecionado={processoSeletivoSelecionado} />
          }
        </div>
      </Perfil>
    </Organizacao>
  );
}

export default NpsProcessoSeletivo;