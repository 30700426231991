import { useContext, useState } from 'react'
import { MdEdit } from "react-icons/md";
import './dadosProcesso.css'
import { ModalEstadoContext } from '../../contexts/ModalEstado';
import { Button } from '../Styles';
import { criptografarDado } from '../../helpers';

function ModalDadosProcesso({ processoSeletivoDetalhes }: any) {
  const { modalExibir } = useContext(ModalEstadoContext)
  const [copiado, setCopiado] = useState(false)

  const abrirModalEditarProcesso = async () => {
    modalExibir("");
    modalExibir("abrirModalEditarProcessoSeletivo");
  };

  const handleCopiar = (id?: number) => {
    if (id) {
      let idEncoded = encodeURIComponent(id.toString())
      let idCriptografado = criptografarDado(idEncoded)
      const url = `${process.env.REACT_APP_URL_HG}/vagas/${idCriptografado}`;
      navigator.clipboard.writeText(url).then(() => {
        setCopiado(true);
        setTimeout(() => setCopiado(false), 3000);
      });
    }
  };

  return (
    <section className='fundoModal'>
      <section className='containerModalCentro containerDetalhesProcesso'>
        <section className="novoContainerTitulo">
          <h1>Sobre o Processo Seletivo:</h1>
          <i className="icone-editar" onClick={() => abrirModalEditarProcesso()}><MdEdit /></i>
        </section>
        <section>
          <span>
            <label>Descrição:</label>
            <p>
              {processoSeletivoDetalhes.descricaoCargo}
            </p>
          </span>
        </section>

        <section className="novoContainerDados">
          <label>Quantidade de vagas:</label>
          <p>
            {processoSeletivoDetalhes.qtnVagas}
          </p>
        </section>

        <section className="novoContainerDados">
          <span>
            <label>Principais atribuições:</label>
            <p>
              {processoSeletivoDetalhes.principaisAtribuicao}
            </p>
          </span>
        </section>

        {processoSeletivoDetalhes.beneficios && <section className="novoContainerDados">
          <span>
            <label>Benefícios:</label>
            <p>
              {processoSeletivoDetalhes.beneficios}
            </p>
          </span>
        </section>}

        <section className="novoContainerDados">
          {processoSeletivoDetalhes.requisitos &&
            <span>
              <label>Habilidades desejadas:</label>
              <p className="texto-destaque">{processoSeletivoDetalhes.requisitos}</p>
            </span>
          }
          <span>
            <label>Formação Acadêmica:</label>
            <p className="texto-destaque">{processoSeletivoDetalhes.formacaoAcademica}</p>
          </span>
          <span>
            <label>PerFil Comportamental:</label>
            <p className="texto-destaque">{processoSeletivoDetalhes.perfilComportamental}</p>
          </span>
        </section>

        <span className="botoesContainer espacamento">
          <button className="btn-detalhes" onClick={() => { modalExibir("") }}>Voltar</button>
          <Button
            isLoading={false}
            onClick={() => handleCopiar(processoSeletivoDetalhes.id)}
            cor="salvar"
            type="button"
          >
            {copiado ? "Copiado!" : 'Copiar Link'}
          </Button>
        </span>
      </section>
    </section>
  )
}

export default ModalDadosProcesso