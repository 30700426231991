import BASEAPI from '../api/config'
import { paramsFiltrarCandidato, payloadAdicionarPergunta, payloadAtualizarPergunta } from '../../interfaces/BancoTalentos'

export function listarCandidatos (params: paramsFiltrarCandidato) {
    return BASEAPI.get(`/listarCandidatos?nomeCompleto=${params.nomeCompleto}&situacao=${params.situacao}&cidade=${params.cidade}&estado=${params.estado}&campanha=${params.campanha}&interesseArea=${params.interesseArea}&interesseRegime=${params.interesseRegime}&pagina=${params.pagina}`)
}

export function adicionarPergunta (payload: payloadAdicionarPergunta) {
    return BASEAPI.post(`/discPergunta`, payload)
}

export function atualizarPergunta (idPergunta: string, idAlternativa: string, payload: payloadAtualizarPergunta) {
    return BASEAPI.patch(`/atualizarDiscPergunta/${idPergunta}/${idAlternativa}`, payload)
}

export function listarPerguntas () {
    return BASEAPI.get(`/listaDiscPerguntas`)
}