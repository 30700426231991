import { payloadAtualizarProcessoSeletivo, payloadCriarProcessoSeletivo, payloadProcessoInscritos, payloadProcessoEtapa, payloadAtualizarSituacaoProcessoSeletivo, payloadCriarProcessoNps, payloadAtualizarProcessoNps, payloadReprovarInscrito, payloadAtualizarProcessoParaRepescagem } from '../../interfaces/processoSeletivo'
import BASEAPI from '../api/config'

export function listarProcessoSeletivo () {
    return BASEAPI.get(`/listarProcessoSeletivo`)
}

export function listarProcessoSeletivoPorId (idProcessoSeletivo:string) {
    return BASEAPI.get(`/listarProcessoSeletivo/${idProcessoSeletivo}`)
}

export function criarProcessoSeletivo (payload: payloadCriarProcessoSeletivo) {
    return BASEAPI.post(`/processoSeletivo`, payload)
}

export function atualizarProcessoSeletivo (payload: payloadAtualizarProcessoSeletivo, idProcessoSeletivo: string) {
    return BASEAPI.patch(`/atualizarProcessoSeletivo/${idProcessoSeletivo}`, payload)
}

export function finalizarProcessoSeletivo (payload: payloadAtualizarSituacaoProcessoSeletivo, idProcessoSeletivo: string) {
    return BASEAPI.put(`/finalizarProcessoSeletivo/${idProcessoSeletivo}`, payload)
}

export function atualizarProcessoInscrito (payload: payloadProcessoInscritos, idProcessoInscrito: string) {
    return BASEAPI.patch(`/processoInscrito/${idProcessoInscrito}`, payload)
}

export function desclassificarCandidato(idProcessoInscrito: string, idProcessoSeletivo: string, payload: payloadReprovarInscrito) {
    return BASEAPI.patch(`/processoInscrito/${idProcessoInscrito}/${idProcessoSeletivo}`, payload)
}

export function criarProcessoEtapas (payload: payloadProcessoEtapa, idProcessoSeletivo: string) {
    return BASEAPI.post(`/processoEtapas/${idProcessoSeletivo}`, payload)
}

export function deletarEtapa (idEtapa: string) {
    return BASEAPI.delete(`/processoEtapas/${idEtapa}`)
}

export function criarProcessoNps (payload: payloadCriarProcessoNps, idProcessoSeletivo: string) {
    return BASEAPI.post(`/processoNps/${idProcessoSeletivo}`, payload)
}

export function atualizarProcessoNps (payload: payloadAtualizarProcessoNps, idProcessoNps: string) {
    return BASEAPI.patch(`/processoNps/${idProcessoNps}`, payload)
}

export function abrirRepescagem(payload: payloadAtualizarProcessoParaRepescagem, idProcessoSeletivo: string) {
    return BASEAPI.patch(`/criarRepescagem/${idProcessoSeletivo}`, payload)
}