import { FormEvent, useContext, useEffect, useState } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { candidatoListaResposta } from '../../interfaces/BancoTalentos'
import { listarCandidatos } from '../../services/BancoTalentos'
import { toast } from "react-toastify"
import Perfil from '../../middleware/perfil'
import FiltrosDefault from '../FiltrosDefault'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import LoadingBarra from '../LoadingBarra'
import avatarDefault from '../../assets/img/avatarDefault.png'
import maisFiltrosImg from '../../assets/img/maisFiltros.png'
import DivSituacao from '../DivSituacao'
import Organizacao from '../../middleware/organizacao'
import ModalDadosCandidato from '../ModalCandidatos'
import { Button, Loader } from '../Styles'

function BancoTalentos() {

    const { modalEstado, exibirModal } = useContext(ModalEstadoContext)

    const [candidatos, setCandidatos] = useState<candidatoListaResposta[]>([])
    const [nomeCompleto, setNomeCompleto] = useState('')
    const [situacao, setSituacao] = useState('')
    const [cidade, setCidade] = useState('')
    const [estado, setEstado] = useState('')
    const [campanha, setCampanha] = useState('')
    const [interesseArea, setInteresseArea] = useState('')
    const [interesseRegime, setInteresseRegime] = useState('')
    const [pagina, setPagina] = useState(1)
    const [maisDados, setMaisDados] = useState(true)
    const [maisFiltros, setMaisFiltros] = useState(false)
    const [processando, setProcessando] = useState(false)
    const [processandoPesquisa, setProcessandoPesquisa] = useState(false)
    const [nenhumResultado, setNenhumResultado] = useState(false)

    const [candidatoSelecionado, setCandidatoSelecionado] = useState<candidatoListaResposta | undefined>(undefined)

    const exibirModalCandidatos = () => exibirModal()

    const abrirModalCandidato = (candidato: candidatoListaResposta) => {
        setCandidatoSelecionado(candidato)
        exibirModalCandidatos()
    }

    const modalMaisFiltros = () => setMaisFiltros(!maisFiltros)

    const scrollInfinito = async (e: any) => {
    
        const { scrollTop, clientHeight, scrollHeight } = e.target
    
        const posicaoScroll = (scrollTop/(scrollHeight - clientHeight)*100)
    
        if (posicaoScroll >= 90 && maisDados) {

            setProcessando(true)

            const novaPagina = pagina + 1

            setPagina(prevStat => prevStat + 1)

            const params = {
                nomeCompleto,
                situacao,
                campanha,
                cidade,
                estado,
                pagina: novaPagina,
                interesseArea,
                interesseRegime
            }

            try {

                const resposta = await listarCandidatos(params)

                if(resposta.data.length === 0) {
                    setProcessando(false)
                    return setMaisDados(false)
                }
    
                if(resposta.status === 200) {
                    setCandidatos(prevtData => [...prevtData, ...resposta.data])
                    setProcessando(false)

                    return
                }
    
            } catch (error) {
    
                setProcessando(false)
                return toast.error("Falha ao buscar mais candidatos")
            }
        }
    }

    const filtrarCandidato = async (e: FormEvent) => {

        e.preventDefault()
        setProcessandoPesquisa(true)
        setMaisFiltros(false)
        
        const params = {
            nomeCompleto,
            situacao,
            campanha,
            cidade,
            estado,
            pagina: 1,
            interesseArea,
            interesseRegime
        }

        try {

            const resposta = await listarCandidatos(params)

            if(resposta.data.length === 0) {
                setCandidatos(resposta.data)
                setProcessandoPesquisa(false)
                setMaisDados(true)
                return setNenhumResultado(true)
            }

            if(resposta.status === 200) {
                setCandidatos(resposta.data)
                setNenhumResultado(false)
                setMaisDados(true)
                return setProcessandoPesquisa(false)
            }

        } catch (error) {

            setProcessandoPesquisa(false)
            return toast.error("Falha ao Listar os Dados")
        }

    }

    useEffect(() => {

        const buscarCandidatos = async () => {

            setNenhumResultado(false)
            setProcessando(true)
    
            const params = {
                nomeCompleto: '',
                situacao: '',
                campanha: '',
                cidade: '',
                estado: '',
                pagina: 1,
                interesseArea: '',
                interesseRegime: ''
            }
    
            try {

                const resposta = await listarCandidatos(params)

                if(resposta.data.lenght === 0) {
                    setNenhumResultado(true)
                    setProcessando(false)

                    return
                }
        
                if (resposta.status === 200) {
                    setCandidatos(resposta.data)
                    setProcessando(false)

                    return
                }

            } catch (error) {

                setProcessando(false)
                return toast.error("Falha ao Listar os Cantidados")
            }
        }

        buscarCandidatos()

    }, [])

    return (
        <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
            <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor']}>
                <div id='paginaContainer'>
                    <FiltrosDefault termo={nomeCompleto} situacao={situacao} placeholder='Pesquise pelo nome do candidato' tipo={""} onChangeTermo={(e) => setNomeCompleto(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={(e) => filtrarCandidato(e)} acaoBtn={() => { }} listaSituacao={[{ nome: 'Todos', valor: '' }, { nome: 'Completo', valor: 'Completo' }, { nome: 'Incompleto', valor: 'Incompleto' }]} genero='feminino' isLoading={processandoPesquisa} maisFiltros={true} acaoMaisFiltro={modalMaisFiltros}/>
                    {processando && <LoadingBarra />}
                    {nenhumResultado && <NenhumResultadoEncontrado />}
                    {modalEstado && candidatoSelecionado && <ModalDadosCandidato candidato={candidatoSelecionado} />}

                    {maisFiltros && <section id='maisOpcoesFiltrosTarefas'>
                        <form id='maisOpcoesFiltrosTarefasContainer' onSubmit={filtrarCandidato}>
                            <span id='filtrosContainerTitulo'>
                                <img id='filtrosAvancadosTarefas' src={maisFiltrosImg}  alt=''/>
                                <p>Filtros Avançados</p>
                            </span>
                            <section id='opcoesFiltrosTarefas'>
                                <section>
                                    <span>
                                        <label htmlFor="nomeCandidado">Nome do Candidato</label>
                                        <input 
                                            type="text"
                                            value={nomeCompleto}
                                            placeholder='Pesquise pelo nome do Candidato'
                                            onChange={(e) => setNomeCompleto(e.target.value)} />
                                    </span>
                                </section>
                                <section>
                                    <span>
                                        <label htmlFor="cidadeCandidato">Cidade</label>
                                        <input
                                            id='cidadeCandidato'
                                            value={cidade}
                                            placeholder='Pesquise pela Cidade do Candidado'
                                            onChange={(e) => setCidade(e.target.value)}
                                        />
                                    </span>

                                    <span>
                                        <label htmlFor="estadosCandidato">Estado</label>
                                        <select id="estadosCandidato" value={estado} onChange={(e) => setEstado(e.target.value)}>
                                            <option value = ''>Selecione um Estado</option>
                                            <option value = 'acre'>Acre</option>
                                            <option value = 'alagoas'>Alagoas</option>
                                            <option value = 'amapá'>Amapá</option>
                                            <option value = 'amazonas'>Amazonas</option>
                                            <option value = 'bahia'>Bahia</option>
                                            <option value = 'ceará'>Ceará</option>
                                            <option value = 'espírito santo'>Espírito Santo</option>
                                            <option value = 'goiás'>Goiás</option>
                                            <option value = 'maranhão'>Maranhão</option>
                                            <option value = 'mato grosso'>Mato Grosso</option>
                                            <option value = 'mato grosso do sul'>Mato Grosso do Sul</option>
                                            <option value = 'minas gerais'>Minas Gerais</option>
                                            <option value = 'pará'>Pará</option>
                                            <option value = 'paraíba'>Paraíba</option>
                                            <option value = 'paraná'>Paraná</option>
                                            <option value = 'pernanbuco'>Pernambuco</option>
                                            <option value = 'piauí'>Piauí</option>
                                            <option value = 'rio de janeiro'>Rio de Janeiro</option>
                                            <option value = 'rio grande do norte'>Rio Grande do Norte</option>
                                            <option value = 'rio grande do sul'>Rio Grande do Sul</option>
                                            <option value = 'rondônica'>Rondônia</option>
                                            <option value = 'roraima'>Roraima</option>
                                            <option value = 'santa catarina'>Santana Catarina</option>
                                            <option value = 'são paulo'>São Paulo</option>
                                            <option value = 'sergipe'>Sergipe</option>
                                            <option value = 'tocantins'>Tocantins</option>
                                        </select>
                                    </span>

                                    <span>
                                        <label htmlFor="situacaoCandidato">Situação do Currículo</label>
                                        <select value={situacao} onChange={(e) => setSituacao(e.target.value)}>
                                            <option value="">Todos</option>
                                            <option value="Completo">Completo</option>
                                            <option value="Incompleto">Incompleto</option>
                                        </select>
                                    </span>
                                </section>

                                <section>
                                    <span>
                                        <label htmlFor="regimeCandidato">Regime de Interesse</label>
                                        <select id="regimeCandidato" value={interesseRegime} onChange={(e) => setInteresseRegime(e.target.value)}>
                                            <option value = ''>Selecione um Regime</option>
                                            <option value = 'Estágio'>Estágio</option>
                                            <option value = 'Clt'>Clt</option>
                                            <option value = 'Contrato'>Contrato</option>
                                            <option value = 'Diarista'>Diarista</option>
                                            <option value = 'Outros'>Outros</option>
                                        </select>
                                    </span>

                                    <span>
                                        <label htmlFor="areaCandidato">Area de Interesse</label>
                                        <select id="areaCandidato" value={interesseArea} onChange={(e) => setInteresseArea(e.target.value)}>
                                            <option value = ''>Selecione uma Area</option>
                                            <option value = 'Administrativo'>Administrativo</option>
                                            <option value = 'Atendimento'>Atendimento</option>
                                            <option value = 'Construção Civil'>Construção Civil</option>
                                            <option value = 'Educação'>Educação</option>
                                            <option value = 'Engenharia'>Engenharia</option>
                                            <option value = 'Finanças e Contabilidade'>Finanças e Contabilidade</option>
                                            <option value = 'Logística'>Logística</option>
                                            <option value = 'Marketing'>Marketing</option>
                                            <option value = 'Recursos Humanos'>Recursos Humanos</option>
                                            <option value = 'Saúde'>Saúde</option>
                                            <option value = 'Tecnologia Da Informação'>Tecnologia Da Informação</option>
                                            <option value = 'Vendas'>Vendas</option>
                                        </select>
                                    </span>
                                    <Perfil perfil={['master']}>
                                        <span>
                                            <label htmlFor="campanhaCandidato">Origem do Cadastro</label>
                                            <input
                                                id='campanhaCandidato'
                                                value={campanha}
                                                placeholder='Pesquise pela Campanha'
                                                onChange={(e) => setCampanha(e.target.value)}
                                            />
                                        </span>
                                    </Perfil>
                                </section>
                            </section>
                            <span id="botoesContainerFiltrosTarefas">
                                <Button type='reset' cor='cancelar' isLoading={processando} onClick={modalMaisFiltros}>Cancelar</Button>
                                <Button type='submit' cor='salvar' isLoading={processando}>
                                    {!processando && 'Buscar Dados'}
                                    {processando && <Loader isLoading={processando}/>}
                                </Button>
                            </span>
                        </form>
                    </section>}

                    <section id='fundoContainerPagina' onScroll={scrollInfinito}>
                        <table id="tabelaListaContainer">
                            <thead>
                                <tr>
                                    <th align='left'>Candidato</th>
                                    <th align='left' className='hide-responsive'>E-mail</th>
                                    <th align='left' className='hide-responsive'>Telefone</th>
                                    <th align='left' className='hide-responsive'>Cidade</th>
                                    <th align='left' className='hide-responsive'>Estado</th>
                                    <th>Situação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {candidatos.map((candidato) => (
                                    <tr key={candidato.id} onClick={() => abrirModalCandidato(candidato)}>
                                        <td className='primeiraColuna'>
                                            <div className='avatarContent'>
                                                <img className='avatar' src={candidato.avatar ? candidato.avatar : avatarDefault} alt='' />
                                                <p>{candidato.nomeCompleto}</p>
                                            </div>
                                        </td>
                                        <td align='left' className='hide-responsive'>{candidato.email}</td>
                                        <td align='left' className='hide-responsive'>{candidato.telefone}</td>
                                        <td className='hide-responsive'>{candidato.cidade}</td>
                                        <td className='hide-responsive'>{candidato.estado}</td>
                                        <td align='center' className='ladoDireitoContent'>
                                            <DivSituacao texto={candidato.situacaoCadastral} cor={candidato.situacaoCadastral.toLocaleLowerCase()} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                </div>
            </Perfil>
        </Organizacao>
    )
}

export default BancoTalentos