import { FormEvent, useContext, useEffect, useState } from "react"
import { payloadCriarProcessoSeletivo, payloadAtualizarProcessoSeletivo } from "../../interfaces/processoSeletivo"
import { Button } from "../Styles"
import { ModalEstadoContext } from "../../contexts/ModalEstado"
import { cepMascara, moedaMascara, converterNumero } from "../../helpers"
import { atualizarProcessoSeletivo, criarProcessoSeletivo, listarProcessoSeletivoPorId } from "../../services/processosSeletivo"
import { useParams } from "react-router-dom"
import { listarCadastros } from "../../services/cadastros"
import { listarUsuariosOrganizacao } from "../../services/usuarios"
import { usuariosRespostaPayload } from "../../interfaces/usuarios"
import { cadastrosRespostaPayload } from "../../interfaces/cadastros"
import Perfil from "../../middleware/perfil"
import { toast } from "react-toastify"

function ModalProcessoSeletivo() {
  const { modalExibir, atualizarDados } = useContext(ModalEstadoContext)
  const { idProcesso } = useParams();

  const [processando, setProcessando] = useState(false)

  const [clientes, setClientes] = useState<cadastrosRespostaPayload[]>([])

  const [resposaveis, setResponsaveis] = useState<usuariosRespostaPayload[]>([])

  const [processoSeletivo, setProcessoSeletivo] = useState("");
  const [idCliente, setIdCliente] = useState("");
  const [idResponsavel, setIdResponsavel] = useState("");
  const [publicarVaga, setPublicarVaga] = useState("");
  const [situacao, setSituacao] = useState("");
  const [titulo, setTitulo] = useState("");
  const [regimeContratacao, setRegimeContratacao] = useState("");
  const [tipoProcesso, setTipoProcesso] = useState("");
  const [salario, setSalario] = useState('');
  const [qtnVagas, setQtnVagas] = useState(1);
  const [jornadaTrabalho, setJornadaTrabalho] = useState("");
  const [modalidadeTrabalho, setModalidadeTrabalho] = useState("");
  const [perfilComportamental, setPerfilComportamental] = useState("");
  const [formacaoAcademica, setFormacaoAcademica] = useState("");
  const [area, setArea] = useState("");
  const [cargoLideranca, setCargoLideranca] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [inscricaoTermino, setInscricaoTermino] = useState("");
  const [previsaoTermino, setPrevisaoTermino] = useState("");
  const [cep, setCep] = useState("");
  const [descricaoCargo, setDescricaoCargo] = useState("");
  const [principaisAtribuicao, setPrincipaisAtribuicao] = useState("");
  const [beneficios, setBeneficios] = useState("");
  const [requisitos, setRequisitos] = useState("");
  const [diferenciais, setDiferenciais] = useState("");

  const fecharModal = () => {
    modalExibir("")
  }

  const listarProcesso = async () => {
    setProcessando(true)
    try {
      if (idProcesso) {
        const processoSeletivoListado = await listarProcessoSeletivoPorId(encodeURIComponent(idProcesso))

        setProcessoSeletivo(processoSeletivoListado.data)
        setIdCliente(processoSeletivoListado.data.idCliente || "");
        setIdResponsavel(processoSeletivoListado.data.idResponsavel || "");
        setPublicarVaga(processoSeletivoListado.data.publicarVaga || "");
        setSituacao(processoSeletivoListado.data.situacao || "");
        setTitulo(processoSeletivoListado.data.titulo || "");
        setRegimeContratacao(processoSeletivoListado.data.regimeContratacao || "");
        setTipoProcesso(processoSeletivoListado.data.tipoProcesso || "");
        setSalario(processoSeletivoListado.data.salario || null);
        setQtnVagas(processoSeletivoListado.data.qtnVagas || 1);
        setJornadaTrabalho(processoSeletivoListado.data.jornadaTrabalho || "");
        setModalidadeTrabalho(processoSeletivoListado.data.modalidadeTrabalho || "");
        setPerfilComportamental(processoSeletivoListado.data.perfilComportamental || "");
        setFormacaoAcademica(processoSeletivoListado.data.formacaoAcademica || "");
        setArea(processoSeletivoListado.data.area || "");
        setCargoLideranca(processoSeletivoListado.data.cargoLideranca);
        setDataInicio(processoSeletivoListado.data.dataInicio || "");
        setInscricaoTermino(processoSeletivoListado.data.inscricaoTermino || "");
        setPrevisaoTermino(processoSeletivoListado.data.previsaoTermino || "");
        setCep(processoSeletivoListado.data.cep || "");
        setDescricaoCargo(processoSeletivoListado.data.descricaoCargo || "");
        setPrincipaisAtribuicao(processoSeletivoListado.data.principaisAtribuicao || "");
        setBeneficios(processoSeletivoListado.data.beneficios || "");
        setRequisitos(processoSeletivoListado.data.requisitos || "");
        setDiferenciais(processoSeletivoListado.data.diferenciais || "");
      }
      setProcessando(false)
    } catch (error) {
      toast.error("Falha ao Listar o Processo Seletivo.")
      return setProcessando(false)
    }
  }

  const buscarClientes = async () => {
    try {
      const params = {
        tipo: 'cliente',
        termo: '',
        pagina: 1,
        situacao: 'ativo'
      }
      const resposta = await listarCadastros(params)
      if (resposta.status === 200) {
        return setClientes(resposta.data)
      }

    } catch (error) {
      setProcessando(false)
      return toast.error("Falha ao Buscar o Clientes.")
    }
  }

  const buscarResponsaveis = async () => {
    try {
      const params = {
        termo: '',
        situacao: 'ativo'
      }
      const resposta = await listarUsuariosOrganizacao(params)
      if (resposta.status === 200) {
        return setResponsaveis(resposta.data)
      }

    } catch (error) {
      setProcessando(false)
      return toast.error("Falha ao Buscar o Clientes.")
    }
  }

  const handleUpdate = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    if (dataInicio > (inscricaoTermino && previsaoTermino)) {
      setProcessando(false)
      return toast.info("A Data de inicio não pode ser maior que a data de termino e a data final.")
    }

    try {
      if (idProcesso) {
        const payload: payloadAtualizarProcessoSeletivo = {
          titulo,
          descricaoCargo,
          principaisAtribuicao,
          salario: converterNumero(salario) || null,
          beneficios: beneficios || "",
          requisitos: requisitos || "",
          qtnVagas,
          area,
          diferenciais: diferenciais || "",
          formacaoAcademica,
          perfilComportamental,
          modalidadeTrabalho,
          jornadaTrabalho: jornadaTrabalho || "",
          regimeContratacao,
          cargoLideranca: cargoLideranca === "true",
          tipoProcesso,
          publicarVaga: publicarVaga === "true",
          idResponsavel,
          situacao,
        };

        const payloadFormatado = {
          ...payload,
          salario: salario ? salario.replace(/[^\d,]/g, '').replace(',', '.').slice(0, -2) : null,
        };
        const idProcessoSeletivo = encodeURIComponent(idProcesso)

        await atualizarProcessoSeletivo(payloadFormatado, idProcessoSeletivo);
        toast.success("Processo Seletivo atualizado com sucesso.");

        setProcessando(false);
        return modalExibir("");

      }
    } catch (error) {
      setProcessando(false)
      return toast.error("Erro ao Atualizar o Processo Seletivo. Tente novamente!");
    }

  }

  const handleCreate = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)

    try {
      const payload: payloadCriarProcessoSeletivo = {
        titulo,
        descricaoCargo,
        principaisAtribuicao,
        salario: converterNumero(salario) === 0 ? null : converterNumero(salario),
        beneficios: beneficios || "",
        requisitos: requisitos || "",
        qtnVagas,
        area,
        diferenciais: diferenciais || "",
        formacaoAcademica,
        perfilComportamental,
        modalidadeTrabalho,
        jornadaTrabalho: jornadaTrabalho || "",
        regimeContratacao,
        cargoLideranca: cargoLideranca === "true",
        tipoProcesso,
        idResponsavel,
        cep,
        dataInicio,
        inscricaoTermino,
        previsaoTermino,
        idCliente
      };

      await criarProcessoSeletivo(payload)
      modalExibir("");
      setProcessando(false);
      atualizarDados()
      return toast.success("Processo Seletivo Criado com Sucesso.")
    } catch (error: any) {
      setProcessando(false)
      return toast.error("Falha ao Criar o Processo Seletivo.")
    }
  }

  useEffect(() => {
    listarProcesso()
    buscarClientes()
    buscarResponsaveis()
  }, [])

  return (
    <div id="fundoModal">
      <section
        className="containerModalCentro overflow-auto"
      >
        <span id="novoContainerTitulo">
          <h1>Processo Seletivo</h1>
        </span>
        <form id="formModalNovo" onSubmit={processoSeletivo ? (e) => handleUpdate(e) : (e) => handleCreate(e)}>
          <section>
            <span>
              <label>Cliente</label>
              <select value={idCliente || ""} onChange={(e) => setIdCliente(e.target.value)} required>
                <option value="" disabled>Selecione uma opção</option>
                {clientes.map((cliente) => (
                  <option key={cliente.id} value={cliente.id}>{cliente.nomeFantasia}</option>
                ))}
              </select>
            </span>
            <Perfil perfil={['master', 'administrativo', 'atendimento']}>
              <>
                <span>
                  <label>Responsavel</label>
                  <select value={idResponsavel || ""} onChange={(e) => setIdResponsavel(e.target.value)} required>
                    <option value="" disabled>Selecione uma opção</option>
                    {resposaveis.map((responsavel) => (
                      <option key={responsavel.id} value={responsavel.id}>{responsavel.nome}</option>
                    ))}
                  </select>
                </span>
                {processoSeletivo && <span>
                  <label htmlFor="">Publicar vaga</label>
                  <select onChange={(e) => { setPublicarVaga(e.target.value) }} value={publicarVaga.toString() === "true" ? "true" : "false"} required>
                    <option value="" disabled>Selecione uma opção</option>
                    <option value="true">Sim</option>
                    <option value="false">Não</option>
                  </select>
                </span>}
              </>
            </Perfil>
          </section>
          <section>
            <span>
              <label htmlFor="titulo">Nome do Cargo</label>
              <input type="text" placeholder='Titulo do Cargo' onChange={(e) => setTitulo(e.target.value)} defaultValue={titulo} maxLength={100} required />
            </span>
            <span>
              <label htmlFor="">Regime</label>
              <select onChange={(e) => setRegimeContratacao(e.target.value)} value={regimeContratacao} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Estágio">Estágio</option>
                <option value="Clt">Clt</option>
                <option value="Contrato">Contrato</option>
                <option value="Diarista">Diarista</option>
                <option value="Outros">Outros</option>
              </select>
            </span>
            <span>
              <label htmlFor="">Tipo de Processo</label>
              <select onChange={(e) => setTipoProcesso(e.target.value)} value={tipoProcesso} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Simplificado" disabled={!!processoSeletivo && tipoProcesso === "Personalizado"}>Simplificado</option>
                <option value="Personalizado">Personalizado</option>
              </select>
            </span>
          </section>
          <section>
            <span>
              <label htmlFor="salario">Salário</label>
              <input type="text" placeholder='Valor do Salário' onChange={(e) => setSalario((e.target.value))} value={moedaMascara(salario)} />
            </span>
            <span>
              <label htmlFor="">Quantidade de vagas</label>
              <input type="number" placeholder='Insira quantas vagas' onChange={(e) => setQtnVagas(parseInt(e.target.value))} min={1} value={qtnVagas} required />
            </span>
            <span>
              <label htmlFor="">Jornada de Trabalho</label>
              <input type="text" placeholder='Jornada de Trabalho' onChange={(e) => setJornadaTrabalho(e.target.value)} value={jornadaTrabalho} maxLength={20} required />
            </span>
            <span>
              <label htmlFor="Modalidade de Trabalho">Modalidade de Trabalho</label>
              <select onChange={(e) => setModalidadeTrabalho(e.target.value)} value={modalidadeTrabalho} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Presencial">Presencial</option>
                <option value="Home-Office">Home-Office</option>
                <option value="Híbrido">Híbrido</option>
              </select>
            </span>
          </section>
          <section>
            <span>
              <label htmlFor="">PerFil Comportamental</label>
              <select onChange={(e) => setPerfilComportamental(e.target.value)} value={perfilComportamental} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Dominante">Dominante</option>
                <option value="Influente">Influente</option>
                <option value="Estável">Estável</option>
                <option value="Condescendente">Condescendente</option>
              </select>
            </span>
            <span>
              <label htmlFor="">Formação Acadêmica</label>
              <select onChange={(e) => setFormacaoAcademica(e.target.value)} value={formacaoAcademica} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Nível Fundamental">Nível Fundamental</option>
                <option value="Nível Médio">Nível Médio</option>
                <option value="Nível Superior">Nível Superior</option>
                <option value="Pós - Graduação">Pós - Graduação</option>
                <option value="Mestrado">Mestrado</option>
                <option value="Doutorado">Doutorado</option>
                <option value="Phd">Phd</option>
              </select>
            </span>
            <span>
              <label htmlFor="">Área</label>
              <select onChange={(e) => setArea(e.target.value)} value={area} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="Tecnologia Da Informação">Tecnologia Da Informação</option>
                <option value="Saúde">Saúde</option>
                <option value="Engenharia">Engenharia</option>
                <option value="Finanças e Contabilidade">Finanças e Contabilidade</option>
                <option value="Administrativo">Administrativo</option>
                <option value="Atendimento">Atendimento</option>
                <option value="Marketing">Marketing</option>
                <option value="Vendas">Vendas</option>
                <option value="Educação">Educação</option>
                <option value="Recursos Humanos">Recursos Humanos</option>
                <option value="Construção Civil">Construção Civil</option>
                <option value="Logística">Logística</option>
              </select>
            </span>
            <span>
              <label htmlFor="">Cargo liderança?</label>
              <select onChange={(e) => setCargoLideranca(e.target.value)} value={cargoLideranca !== undefined ? cargoLideranca.toString() : ""} required>
                <option value="" disabled>Selecione uma opção</option>
                <option value="true">Sim</option>
                <option value="false">Não</option>
              </select>
            </span>
          </section>

          <section>
            <span>
              <label htmlFor="">Data de Inicio Do Processo</label>
              <input type="date" name="" id="" onChange={(e) => setDataInicio(e.target.value)} disabled={!!processoSeletivo} value={dataInicio ? new Date(dataInicio).toISOString().split("T")[0] : ""} maxLength={8} required />
            </span>
            <span>
              <label htmlFor="">Término da Inscrição</label>
              <input type="date" name="" id="" onChange={(e) => setInscricaoTermino(e.target.value)} disabled={!!processoSeletivo} value={inscricaoTermino ? new Date(inscricaoTermino).toISOString().split("T")[0] : ""} required min={dataInicio} />
            </span>
            <span>
              <label htmlFor="">Previsão de termino do Processo:</label>
              <input type="date" name="" id="" onChange={(e) => setPrevisaoTermino(e.target.value)} disabled={!!processoSeletivo} value={previsaoTermino ? new Date(previsaoTermino).toISOString().split("T")[0] : ""} required min={inscricaoTermino} />
            </span>
          </section>
          <section>
            {!processoSeletivo && <span>
              <label htmlFor="">CEP</label>
              <input type="text" placeholder='Digite o CEP da localização da vaga' minLength={11} maxLength={11} onChange={(e) => setCep(cepMascara(e.target.value))} value={cep} required />
            </span>}
          </section>

          <section>
            <span>
              <label htmlFor="">Descrição do Cargo</label>
              <textarea name="" className="areaTexto" rows={3} onChange={(e) => setDescricaoCargo(e.target.value)} defaultValue={descricaoCargo} required></textarea>
            </span>
            <span>
              <label htmlFor="">Principais Atribuições</label>
              <textarea name="" className="areaTexto" rows={3} onChange={(e) => setPrincipaisAtribuicao(e.target.value)} defaultValue={principaisAtribuicao} maxLength={500} required></textarea>
            </span>
          </section>

          <section>
            <span>
              <label htmlFor="">BeneFicios</label>
              <textarea name="" className="areaTexto" rows={3} onChange={(e) => setBeneficios(e.target.value)} defaultValue={beneficios} maxLength={500}></textarea>
            </span>
            <span>
              <label htmlFor="">Requisitos</label>
              <textarea name="" className="areaTexto" rows={3} onChange={(e) => setRequisitos(e.target.value)} defaultValue={requisitos} maxLength={500}></textarea>
            </span>
            <span>
              <label htmlFor="">Diferenciais</label>
              <textarea name="" className="areaTexto" rows={3} onChange={(e) => setDiferenciais(e.target.value)} defaultValue={diferenciais} maxLength={500}></textarea>
            </span>
          </section>

          <span id="botoesContainer">
            <Button type="button" cor="cancelar" onClick={fecharModal} isLoading={processando}>
              Cancelar
            </Button>
            <Button type="submit" cor="enviar" isLoading={processando}>
              {!processoSeletivo ? "Adicionar Processo" : "Atualizar Processo"}
            </Button>
          </span>
        </form>
      </section>
    </div>
  )
}

export default ModalProcessoSeletivo