import { useState, useEffect, useContext, FormEvent } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { listarContratos, dadosContratos } from '../../services/contratos'
import { Button, Loader } from '../Styles'
import ModalContratos from '../ModalContratos'
import Perfil from '../../middleware/perfil'
import FiltrosDefault from '../FiltrosDefault'
import avatarDefault from '../../assets/img/avatarDefault.png'
import DivSituacao from '../DivSituacao'
import LoagindBarra from '../LoadingBarra'
import Notificacoes from '../Notificacoes'
import erroImg from '../../assets/img/erroFechar.png'
import NenhumResultadoEncontrado from '../NenhumResultadoEncontrato'
import { contratosListaResposta } from '../../interfaces/contratos'
import ImprimirContato from '../../reports/imprimirContrato'

function Contratos () {

    const { atualizarInformacoes, modal, modalExibir } = useContext(ModalEstadoContext)

    const [contratos, setContratos] = useState<contratosListaResposta[]>([])
    const [termo, setTermo] = useState('')
    const [situacao, setSituacao] = useState('')
    const [processando, setProcessando] = useState(false)
    const [processandoPesquisa, setProcessandoPesquisa] = useState(false)
    const [processandoImprimirContrato, setProcessandoImprimirContato] = useState({
        index: -1,
        exibir: false
    })
    const [nenhumResultado, setNenhumResultado] = useState(false)
    const [erro, setErro] = useState(false)

    const exibirModalNovoModelo = () => {
        
        return modalExibir('contratos')
    }


    const imprimirContrato = async (idContrato: string, index: number) => {

        setProcessandoImprimirContato({
            index,
            exibir: true
        })

        try {

            const resposta = await dadosContratos(idContrato)

            if(resposta.status === 200) {

                setProcessandoImprimirContato({
                    index: -1,
                    exibir: false
                })

                const contrato = ImprimirContato(resposta.data)
                const novaJanela = window.open('', '_blank')

                if (novaJanela) {

                    novaJanela.document.open()
                    novaJanela.document.write(contrato)
                    novaJanela.document.close()
            
                    novaJanela.onload = () => {
                        novaJanela.focus()
                        novaJanela.print()
                        novaJanela.close()
                    }
                }
            }

        } catch (error) {

            setProcessandoImprimirContato({
                index: -1,
                exibir: false
            })
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 5000)
            
        }
    }

    const buscarContratos = async (e: FormEvent) => {

        e.preventDefault()
        setNenhumResultado(false)
        setProcessandoPesquisa(true)

        const params = {
            termo,
            situacao
        }

        try {

            const resposta = await listarContratos(params)

            if (resposta.data.length === 0) {
                setContratos(resposta.data)
                setProcessandoPesquisa(false)
                return setNenhumResultado(true)
            }

            if (resposta.status === 200) {
                setContratos(resposta.data)

                return setProcessandoPesquisa(false)
            }
            
        } catch (error) {
            setProcessandoPesquisa(false)
            setErro(true)

            return setTimeout(() => {
                setErro(false)
            }, 2000)
        }
    }

    useEffect(() => {

        const buscarContratos = async () => {

            setNenhumResultado(false)
            setProcessando(true)

            const params = {
                    termo: '',
                    situacao: ''
                }

            try {

                const resposta = await listarContratos(params)

                if (resposta.data.length === 0) {
                    setContratos(resposta.data)
                    setProcessando(false)
                    return setNenhumResultado(true)
                }

                if (resposta.status === 200) {

                    setContratos(resposta.data)

                    return setProcessando(false)
                }
                
            } catch (error) {
                
            }
        }

        buscarContratos ()

    }, [atualizarInformacoes])

    return (
        <Perfil perfil={['master', 'administrativo', 'atendimento']}>
            <div id='paginaContainer'>
                {modal.nome === 'contratos' && <ModalContratos/>}
                {processando && <LoagindBarra />}
                {nenhumResultado && <NenhumResultadoEncontrado />}
                {erro && <Notificacoes icone={erroImg} texto='Falha ao Processar a Solicitação!' cor='erro'/>}
                <FiltrosDefault termo={termo} situacao={situacao} placeholder='Pesquise pelo nome fantasia do cliente' tipo={'Contrato'} onChangeTermo={(e) => setTermo(e.target.value)} onChangeSituacao={(e) => setSituacao(e.target.value)} onSubmit={buscarContratos} acaoBtn={exibirModalNovoModelo} listaSituacao={[{nome: 'Todos', valor: ''}, {nome: 'Ativos', valor: 'Ativo'}, {nome: 'Finalizados', valor: 'Finalizado'},  {nome: 'Cancelados', valor: 'Cancelado'}]} genero='masculino' isLoading={processandoPesquisa}/>

                <section id='fundoContainerPagina'>
                    <table id='tabelaListaContainer'>
                        <thead>
                            <tr>
                                <th id='ladoEsquerdoContent' align='left'>Cliente</th>
                                <th align='left'>Contrato</th>
                                <th id='solicitacaoDocumento' align='left'>Criado por</th>
                                <th align='center'>Data</th>
                                <th align='center'>Situação</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {contratos.map((contrato, index) => (
                                <tr key={contrato.id}>
                                    <td id='primeiraColuna'>{contrato.cadastro.nomeFantasia}</td>
                                    <td>{contrato.contratosModelo.descricao}</td>
                                    <td align='center' className='usuarioResponsavelContent'>
                                        <img className='usuarioResponsavel' src={contrato.usuario.avatar ? contrato.usuario.avatar : avatarDefault} alt='' />
                                            {contrato.usuario.nome}
                                    </td>
                                    <td align='center'>
                                        {new Date(contrato.criado).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo', 
                                            hour: '2-digit', 
                                            minute: '2-digit',
                                            day: '2-digit', 
                                            month: '2-digit', 
                                            year: 'numeric' 
                                        }).replace(',', ' às ')}
                                    </td>
                                    <td align='center'>
                                        <DivSituacao texto={`${contrato.situacao}`} cor={contrato.situacao.toLowerCase().replace(/\s+/g, '')}/>
                                    </td>
                                    <td align='center' id='ladoDireitoContent'>
                                        <Button cor='novo' type='button' isLoading={(processandoImprimirContrato.exibir && processandoImprimirContrato.index === index)} onClick={() => imprimirContrato(contrato.id, index)}>
                                            {processandoImprimirContrato.index !== index && 'Imprimir Contrato'}
                                            {processandoImprimirContrato.exibir && processandoImprimirContrato.index === index && <Loader isLoading={processandoImprimirContrato.exibir}/>}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            </div>
        </Perfil>
    )
}

export default Contratos
