import { useState, FormEvent, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { novoCentro, atualizarCentro } from '../../services/centros'
import { ModalCentroDados } from '../../interfaces/centros'
import { Button, Loader } from '../Styles'
import { toast } from 'react-toastify'
import contasGerenciaisImg from '../../assets/img/contasGerenciais.png'
import '../../styles/modal.css'


function ModalCentros ({ centro }: ModalCentroDados) {

    const navigate = useNavigate ()
    const location = useLocation()

    const { exibirModal, atualizarDados } = useContext(ModalEstadoContext)

    const { pathname, search } = location

    const [descricao, setDescricao] = useState(centro?.descricao || '')
    const [tipo, setTipo] = useState(centro?.tipo || '')
    const [situacao, setSituacao] = useState(centro?.situacao || '')
    const [processando, setProcessando] = useState (false)

    function ocultarModal () {

        navigate(`${pathname}`)

        return exibirModal ()
    }

    const cadastrarCentro = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(descricao === undefined || tipo === undefined){
            return setProcessando(false)
        }

        try {

            const payload = {
                descricao,
                tipo
            }

            const resposta = await novoCentro(payload)

            if(resposta.status === 201) {
                setProcessando(false)
                atualizarDados()
                
                toast.success('Dados Salvos com Sucesso')

                return
            }
            
        } catch (error) {
            
            setProcessando(false)
            toast.error('Falha ao Cadastrar o Centro')
            
            return
        }

    }

    const updateCentro = async (e:FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        const paramsUrl = new URLSearchParams(search)
        const idCentro = paramsUrl.get('idCentro')

        if (idCentro === null) {
            return setProcessando(false)
        }

        if(descricao === undefined || tipo === undefined || situacao === undefined) {
            return setProcessando(false)
        }

        
        try {
            
            const payload = {
                descricao,
                tipo,
                situacao
            }

            const resposta = await atualizarCentro(idCentro, payload)

            if (resposta.status === 200) {
                setProcessando(false)
                atualizarDados()

                toast.success('Dados Atualizados com Suceso')

                return
            }
            
        } catch (error) {

            setProcessando(false)

            toast.error('Falha ao Processar a Solicitação')

            return
        }
    }
    


    return (
        <div id = 'fundoModal'>
            <section id='containerModalNovo'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={contasGerenciaisImg} alt='' />
                    <p>{!centro ? 'Cadastrar Centro': 'Vizualizar Centro'}</p>
                </span>
                <form id='formModalNovo' onSubmit={centro ? updateCentro : cadastrarCentro}>
                    <section>
                        <span>
                            <label htmlFor="descricaoCentro">Descrição</label>
                            <input
                                id='descricaoCentro'
                                value={descricao} 
                                onChange={(e) => setDescricao(e.target.value)}
                                type="text"
                                required/>
                        </span>

                        {centro && <span>
                            <label htmlFor="situacaoCento">Situacao</label>
                            <select id="situacaoCento" value={situacao} onChange={(e) => setSituacao(e.target.value)} required>
                                <option value="ativo">Ativo</option>
                                <option value="inativo">Inativo</option>
                            </select>
                        </span>}
                    </section>

                    <section>
                        <span>
                            <label htmlFor="tipoCento">Tipo</label>
                            <select id="tipoCentro" value={tipo} onChange={(e) => setTipo(e.target.value)} required>
                                <option value=""></option>
                                <option value="Centro de Resultado">Centro de Resultado</option>
                                <option value="Centro de Custo">Centro de Custo</option>
                                <option value="Investimentos">Atividades de Investimentos</option>
                                <option value="Participações">Participações</option>
                            </select>
                        </span>
                    </section>
               
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalCentros
