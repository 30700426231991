import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles";
import DashboardProcessoSeletivo from "../../components/DashboardProcessoSeletivo";

function DashboardProcessoSeletivoPage() {

  useEffect(() => {
    document.title = "Dashboard Processo Seletivo | Gesttor"
  }, [])

  return (
    <DadosContainerDefault>
      <DashboardProcessoSeletivo />
    </DadosContainerDefault>
  );
}

export default DashboardProcessoSeletivoPage;