import { FormEvent, useContext, useEffect, useState } from "react";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import Organizacao from "../../middleware/organizacao";
import Perfil from "../../middleware/perfil";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { atualizarProcessoInscrito, criarProcessoEtapas, deletarEtapa, desclassificarCandidato, finalizarProcessoSeletivo, listarProcessoSeletivoPorId } from "../../services/processosSeletivo";
import { ListaDeProcessoSeletivo, ProcessoEtapas } from "../../interfaces/processoSeletivo";
import ModalDadosProcesso from "../ModalDadosProcesso";
import "./dadosProcessoSeletivo.css";
import { candidatoListaResposta } from "../../interfaces/BancoTalentos";
import ModalDadosCandidato from "../ModalCandidatos";
import ModalProcessoSeletivo from "../ModalProcessoSeletivo";
import { criptografarDado } from "../../helpers";
import { IoMdAddCircle } from "react-icons/io";
import { BiSolidDislike } from "react-icons/bi";
import { toast } from "react-toastify"
import ModalProcessoNps from "../ModalProcessoNps";

function VisualizarProcessoSeletivo() {
  const { modal, modalExibir, atualizarDados, atualizarInformacoes } = useContext(ModalEstadoContext);
  const params = useParams();
  const navigate = useNavigate()

  const [processoSeletivo, setProcessoSeletivo] = useState<ListaDeProcessoSeletivo>();
  const [candidatoSelecionado, setCandidatoSelecionado] = useState<candidatoListaResposta>();
  const [idProcessoInscritoSelecionado, setIdProcessoInscritoSelecionado] = useState<string>("")
  const [processoEtapas, setProcessoEtapas] = useState<ProcessoEtapas[]>(processoSeletivo?.processoEtapas || []);

  const [etapas, setEtapas] = useState<string[]>([]);
  const [novaEtapa, setNovaEtapa] = useState<string>("");
  const [proximaEtapa, setProximaEtapa] = useState<string>("");
  const [feedback, setFeedback] = useState<string>("");

  const reorganizarEtapas = (etapas: ProcessoEtapas[]) => {
    const etapasIniciais = ["Análise Curricular", "Avaliação Disc"];
    const etapasFinais = ["Entrevista", "Aprovado"];
    const etapasAdicionais = etapas.filter((etapa: any) => !etapasIniciais.includes(etapa.etapa) && !etapasFinais.includes(etapa.etapa));

    const etapasIniciaisObjetos = etapas.filter((etapa) =>
      etapasIniciais.includes(etapa.etapa)
    );
    const etapasFinaisObjetos = etapas.filter((etapa) =>
      etapasFinais.includes(etapa.etapa)
    );
    setProcessoEtapas([
      ...etapasIniciaisObjetos,
      ...etapasAdicionais,
      ...etapasFinaisObjetos,
    ])

    return [
      ...etapasIniciaisObjetos,
      ...etapasAdicionais,
      ...etapasFinaisObjetos,
    ]
  }

  const abrirModalCurriculoCandidato = (candidato: candidatoListaResposta) => {
    setCandidatoSelecionado(candidato)
    modalExibir("DadosCurriculoCandidato")
  }

  const abrirModal = (tipoModal: string) => {
    modalExibir(tipoModal)
  }

  const adicionarEtapa = async (idProcessoSeletivo: string) => {
    if (novaEtapa.trim() !== "") {
      setEtapas([...etapas, novaEtapa.trim()]);

      setNovaEtapa("");
      const payload = {
        etapa: novaEtapa
      }

      try {
        const idProcessoSeletivoCript = criptografarDado(idProcessoSeletivo)
        const response = await criarProcessoEtapas(payload, idProcessoSeletivoCript)
        if (response.status === 201) {
          atualizarDados()
          toast.success("Etapa Criada com Sucesso.")
        }
      } catch (error) {
        toast.error("Falha ao Adicionar Etapa no Processo Seletivo.")
      }
    }
  };

  const atualizarEtapaCandidato = async () => {
    try {
      let payload = {
        etapaProcesso: proximaEtapa
      };

      const encodedIdProcessoInscrito = encodeURIComponent(idProcessoInscritoSelecionado);
      const idProcessoInscritoCript = criptografarDado(encodedIdProcessoInscrito);

      const proximaEtapaAtualizado = await atualizarProcessoInscrito(payload, idProcessoInscritoCript)

      if (proximaEtapaAtualizado.status === 200) {
        toast.success("Etapa do Candidato Atualizado com Sucesso.")
        atualizarDados()
        modalExibir("")
      }

    } catch (error) {
      toast.error("Falha ao Atualizar Etapa do Candidato.")
    }
  }

  const handleDeleteEtapa = async (idEtapa: number) => {
    try {

      const encodedIdEtapa = encodeURIComponent(idEtapa);
      const idEtapaCript = criptografarDado(encodedIdEtapa);

      const etapaDeletada = await deletarEtapa(idEtapaCript)
      if (etapaDeletada.status === 204) {
        toast.success("Etapa Deletado com Sucesso.")
        return atualizarDados()
      }
    } catch (error) {
      toast.error("Falha ao Deletar Etapa do Processo Seletivo.")
    }
  }

  const finalizarProcessoSeletivoSituacao = async () => {
    if (!processoSeletivo?.id) {
      return toast.error("Id do Processo Seletivo não encontrado. Por favor, tente novamente!")
    }

    const candidatosSemFeedback = processoSeletivo.processoInscritos.some((inscrito) => inscrito.situacaoCandidatura === "Participando" && inscrito.etapaProcesso !== "Aprovado")

    if (candidatosSemFeedback) {
      return toast.info("Para finalizar o Processo Seletivo, é necessário que todos os candidatos não selecionados recebam um feedback.")
    }
    try {
      const payload = {
        idResponsavel: processoSeletivo.idResponsavel,
        dataInicio: processoSeletivo.dataInicio,
        situacao: "nps"
      };

      const idProcessoSeletivo = encodeURIComponent(processoSeletivo.id)
      const idProcessoSeletivoCript = criptografarDado(idProcessoSeletivo)
      const response = await finalizarProcessoSeletivo(payload, idProcessoSeletivoCript);

      if (response.status === 200) {
        toast.success("Processo Finalizado com Sucesso.")
        atualizarDados()
        return navigate("/processos-seletivos?meus-processos=true")
      }
    } catch (error) {
      toast.error("Falha ao Finalizar Processo Seletivo.")
    }
  }

  const reprovarCandidato = async (e: FormEvent) => {
    e.preventDefault()
    try {

      if (!feedback) return toast.info("É necessario colocar um feedback para o candidato")
      if (!processoSeletivo) return toast.info("Processo Seletivo não encontrado.")
      const payload = {
        feedback,
        idCandidato: candidatoSelecionado?.id,
        idCadastro: processoSeletivo.idCliente,
      }

      const encodedIdProcessoInscrito = encodeURIComponent(idProcessoInscritoSelecionado.toString());
      const idProcessoInscritoCript = criptografarDado(encodedIdProcessoInscrito);
      const idProcessoSeletivoCript = criptografarDado(processoSeletivo.id.toString());

      const reprovarCandidato = await desclassificarCandidato(idProcessoInscritoCript, idProcessoSeletivoCript, payload);

      if (reprovarCandidato.status === 200) {
        toast.success("Candidato Reprovado com Sucesso.")
        atualizarDados()
        modalExibir("")
      }
    } catch (error) {
      console.log(error)
      toast.error("Falha ao Atualizar Etapa do Candidato.")
    }
  }

  useEffect(() => {
    const listarProcessoSeletivo = async () => {
      if (!params.idProcesso) return;
      try {
        const encodedIdProcesso = encodeURIComponent(params.idProcesso);

        const processoSeletivoListado = await toast.promise(
          listarProcessoSeletivoPorId(encodedIdProcesso),
          {
            pending: 'Processando a sua Solicitação. Por favor, aguarde!'
          }
        )
        setProcessoSeletivo(processoSeletivoListado.data)
        reorganizarEtapas(processoSeletivoListado.data.processoEtapas || []);
      } catch (error) {
        toast.error("Falha ao Listar Processo Seletivo.")
      }
    }

    listarProcessoSeletivo()
  }, [params, atualizarInformacoes])

  return (
    <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
      <Perfil perfil={["master", "administrativo", "atendimento", "consultor"]}>
        <section>
          <header className="header-processo-seletivo">
            <img className="avatarCadastroProcessoSeletivo" src={processoSeletivo?.cadastro.avatar ? processoSeletivo.cadastro.avatar : ''} alt="" />
            <section className="detalhes-processo-seletivo">
              <h2>{processoSeletivo?.titulo}</h2>
              <p className="dados-empresa">{processoSeletivo?.cadastro.nomeFantasia}</p>
              <p className="dados-empresa">{processoSeletivo?.localTrabalhoCidade} - {processoSeletivo?.localTrabalhoEstado}</p>
            </section>
            <section className="dados-processo-seletivo">
              <section className="section-tags">
                <span className="tag tag-padrao">{processoSeletivo?.modalidadeTrabalho}</span>
                {processoSeletivo?.salario && <span className="tag tag-padrao">R$ {processoSeletivo?.salario}</span>}
                {processoSeletivo && <span className="tag nome-azul">Publicada em {new Date(processoSeletivo?.dataInicio).toLocaleDateString()}</span>}
                <span className={`tag nome-${processoSeletivo?.publicarVaga ? "azul" : "amarelo"}`}>{processoSeletivo?.situacao}</span>
              </section>
              <section className="section-tags">
                <span className="tag tag-padrao">{processoSeletivo?.regimeContratacao}</span>
                <span className="tag tag-padrao">{processoSeletivo?.formacaoAcademica}</span>
                <span className="tag tag-padrao">{processoSeletivo?.tipoProcesso}</span>
                {processoSeletivo && <span className="tag nome-vermelho">Encerra em {new Date(processoSeletivo.previsaoTermino).toLocaleDateString()}</span>}
              </section>
            </section>
            <section className="botoesContainer"></section>
            {(processoSeletivo?.situacao === "nps" || processoSeletivo?.situacao === "Finalizado") && <Perfil perfil={["master"]}>
              <button className="btn-detalhes" onClick={() => { abrirModal("ModalNps")}}>NPS</button>
            </Perfil>}
            {processoSeletivo?.situacao !== "Finalizado" && processoSeletivo?.situacao !== "nps" && <button className="btn-detalhes btn-finalizar" onClick={() => finalizarProcessoSeletivoSituacao()}>Finalizar Processo</button>}
            {processoSeletivo?.tipoProcesso === "Personalizado" && processoSeletivo?.situacao !== "Finalizado" && processoSeletivo?.situacao !== "nps" && <button className="btn-detalhes" onClick={() => abrirModal("ModalAdicionarEtapas")}>Adicionar Etapas</button>}
            <button className="btn-detalhes" onClick={() => abrirModal("ModalMaisDetalhes")}>Mais Detalhes</button>
          </header>
          <section className="cartoes-container">
            {processoEtapas.map((etapa: any) => {
              return (
                <div className="cartao" key={etapa.id}>
                  <header className="cabecalho-amarelo">
                    <h2 className="titulo">{etapa.etapa}</h2>
                  </header>
                  <div className="conteudo">
                    {processoSeletivo && processoSeletivo.processoInscritos.filter((participante) => participante.situacaoCandidatura !== "Reprovado" && participante.etapaProcesso === etapa.etapa)
                      .map((participante) => (
                        <div className="item" key={participante.id}>
                          <img src={participante.bancoTalento.avatar} alt="" className="item-avatar" />
                          <div className="item-detalhes" onClick={() => abrirModalCurriculoCandidato(participante.bancoTalento)}>
                            <p className="item-nome">{participante.bancoTalento.nomeCompleto}</p>
                            <p className="item-profissao">{participante.bancoTalento.profissao}</p>
                          </div>
                          <div className="item-acoes">
                            <FaArrowAltCircleRight className="icone" onClick={(e) => { modalExibir("ModalProximaEtapa"); setIdProcessoInscritoSelecionado(participante.id.toString()) }} />
                            <BiSolidDislike className="icone" onClick={() => { modalExibir("ModalReprovarCandidato"); setCandidatoSelecionado(participante.bancoTalento); setIdProcessoInscritoSelecionado(participante.id.toString()) }} />
                          </div>
                        </div>
                      ))}

                    {processoSeletivo &&
                      processoSeletivo.processoInscritos
                        .filter(
                          (participante) =>
                            participante.etapaProcesso === etapa.etapa && participante.situacaoCandidatura === "Reprovado"
                        )
                        .map((participante) => (
                          <div className="item reprovado" key={participante.id}>
                            <img src={participante.bancoTalento.avatar} alt="" className="item-avatar" />
                            <div className="item-detalhes" onClick={() => abrirModalCurriculoCandidato(participante.bancoTalento)}>
                              <p className="item-nome">{participante.bancoTalento.nomeCompleto}</p>
                              <p className="item-profissao">{participante.bancoTalento.profissao}</p>
                            </div>
                            <div className="item-acoes">
                              <BiSolidDislike className="icone" />
                            </div>
                          </div>
                        ))}
                  </div>
                </div>)
            }
            )}
          </section>
          {modal.nome === "ModalMaisDetalhes" && <ModalDadosProcesso processoSeletivoDetalhes={processoSeletivo} />}
          {modal.nome === "DadosCurriculoCandidato" && candidatoSelecionado && <ModalDadosCandidato candidato={candidatoSelecionado} />}
          {modal.nome === "abrirModalEditarProcessoSeletivo" && <ModalProcessoSeletivo />}
          {modal.nome === "ModalAdicionarEtapas" && processoSeletivo && (
            <section className='fundoModal'>
              <section className='containerModalCentro containerDetalhesProcesso'>
                <section className="novoContainerTitulo">
                  <h1>Adicionar Etapas:</h1>
                </section>
                <section>
                  <label>Digite a Etapa:</label>
                  <div className="campo-etapas">
                    <input
                      type="text"
                      value={novaEtapa}
                      onChange={(e) => setNovaEtapa(e.target.value)}
                      placeholder="Digite uma etapa"
                    />
                    <button className="btn-adicionar-etapa" onClick={() => adicionarEtapa(processoSeletivo.id.toString())}>
                      <IoMdAddCircle />
                    </button>
                  </div>
                  <ul className="lista-etapas">
                    {processoEtapas.map((etapa) => {
                      const hasCandidatos = processoSeletivo.processoInscritos.some(
                        (inscrito) => inscrito.etapaProcesso === etapa.etapa
                      );
                      return (
                        <li key={etapa.id} className="item-etapa">
                          {etapa.etapa}
                          {!["Análise Curricular", "Avaliação Disc", "Entrevista", "Aprovado"].includes(etapa.etapa) && !hasCandidatos && (
                            <MdDelete className="icone" onClick={() => handleDeleteEtapa(etapa.id)} />
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </section>
                <span className="botoesContainer">
                  <button className="btn-detalhes" onClick={() => modalExibir("")}>
                    Voltar
                  </button>
                </span>
              </section>
            </section>
          )}
          {modal.nome === "ModalProximaEtapa" && processoSeletivo && (
            <section className='fundoModal'>
              <section className='containerModalCentro'>
                <section className="proximaEtapa">
                  <label>Avançar Etapa do candidato:</label>
                  <span className="campo-etapas">
                    <select onChange={(e) => setProximaEtapa(e.target.value)} value={proximaEtapa || ""}>
                      <option value="" disabled>Selecione uma opção</option>
                      {processoEtapas.map((etapa) => (
                        <option value={etapa.etapa} key={etapa.id}>{etapa.etapa}</option>
                      ))
                      }
                    </select>
                  </span>
                </section>
                <span className="botoesContainer">
                  <button className="btn-detalhes-fechar" onClick={() => modalExibir("")}>
                    Fechar
                  </button>
                  <button className="btn-detalhes" onClick={() => { atualizarEtapaCandidato() }}>
                    Salvar
                  </button>
                </span>
              </section>
            </section>
          )}
          {modal.nome === "ModalNps" && processoSeletivo && <ModalProcessoNps processoSeletivoSelecionado={processoSeletivo} />}
          {modal.nome === "ModalReprovarCandidato" && (
            <section className='fundoModal'>
              <section className='containerModalCentro'>
                <section className="novoContainerTitulo">
                  <label>Feedaback do Candidato</label>
                </section>
                <section>
                  <span>
                    <textarea 
                        placeholder="Motivo do candidato está sendo desclassificado do processo seletivo"
                        rows={10} 
                        className="textAreaModal" 
                        maxLength={500} 
                        onChange={(e) => setFeedback(e.target.value)} 
                        required> 
                    </textarea>
                  </span>
                </section>
                <span className="botoesContainer">
                  <button className="btn-detalhes-fechar" onClick={() => modalExibir("")}>
                    Fechar
                  </button>
                  <button className="btn-detalhes" onClick={(e: any) => reprovarCandidato(e)}>
                    Salvar
                  </button>
                </span>
              </section>
            </section>
          )}
        </section>
      </Perfil>
    </Organizacao>
  );
}

export default VisualizarProcessoSeletivo;
