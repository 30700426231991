import { useState, FormEvent, useContext } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { atualizarAvatar } from '../../services/cadastros'
import { Button, Loader } from '../Styles'
import { toast } from 'react-toastify'
import { ModalCadastrosDados } from '../../interfaces/cadastros'
import { criptografarDado } from '../../helpers'
import atualizarIcone from '../../assets/img/usuario.png'
import '../../styles/modal.css'


function ModalAtualizarAvatarCadastro ({ cadastro } : ModalCadastrosDados) {

    const { modalExibir, atualizarDados } = useContext(ModalEstadoContext)

    const [avatarSelecionado, setAvatarSelecionado] = useState<File | null>(null)
    const [avatarPreview, setAvatarPreview] = useState<string | ArrayBuffer | null>(null)
    const [processando, setProcessando] = useState (false)

    const ocultarModal = () => modalExibir('')

    const atualizarAvatarLocal = (e:any) => {
        const arquivo = e.target.files[0]

        if(arquivo) {
            const extensaoPermitida = ['.png', '.jpg', '.jpeg']
            const extensaoArquivo = arquivo.name.split('.').pop().toLowerCase()

            if(extensaoPermitida.includes(`.${extensaoArquivo}`)) {

                const rendenrizar = new FileReader()
        
                rendenrizar.onloadend = () => {
                    setAvatarPreview(rendenrizar.result)
                }
        
                rendenrizar.readAsDataURL(arquivo)

                return setAvatarSelecionado(arquivo)
            } else {
                setAvatarSelecionado(null)
                return toast.warning('Tipo de Imagem não Permitida')
            }
        }
    }

    const enviarImagem = async (e: FormEvent) => {

        e.preventDefault()
        setProcessando(true)

        if(!avatarSelecionado) {
            return
        }

        if(!cadastro) {
            toast.error('Não foi possível difinir parametros obrigatórios')
            modalExibir('')

            return
        }

        const payload = {
            avatar: avatarSelecionado
        }

        try {

            const codifitarCadastro = criptografarDado(cadastro.id.toString())

            const resposta = await atualizarAvatar(codifitarCadastro, payload)

            if (resposta.status === 200) {

                setProcessando(false)
                toast.success('Dados Atualizados com Suceso')
                atualizarDados()

                modalExibir('')

                return
            }
            
        } catch (error) {
            
            setProcessando(false)
            toast.error('Ocorreu um erro ao tentar salvar os dados. Tente Novamente')

            return
        }
        
    }


    return (
        <div id = 'fundoModal'>
            <section className='containerModalCentro'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={atualizarIcone} alt='' />
                    <p>Atualizar Avatar</p>
                </span>
                <form id='formModalNovo' onSubmit={enviarImagem}>
                    {avatarPreview && <section>
                        <span>
                            <img
                                src={avatarPreview as string}
                                alt=''
                                style={{ 
                                    width: '300px', 
                                    height: '300px', 
                                    objectFit: 'cover', 
                                    borderRadius: '50%', 
                                    padding: '10px'
                                }}
                            />
                        </span>
                    </section>}
                    <section>
                        <span>
                            <label htmlFor="nomeUsario">Selecione a Imagem</label>
                            <input
                                id='imagemAvatar' 
                                type="file" 
                                onChange={atualizarAvatarLocal} 
                                accept="image/*"
                                required 
                            />
                        </span>

                    </section>
                    
                    <span id="botoesContainer">
                        <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                        <Button type='submit' cor='salvar' isLoading={processando}>
                            {!processando && 'Salvar Dados'}
                            {processando && <Loader isLoading={processando}/>}
                        </Button>
                    </span>
                </form>
            </section>
        </div>
    )
}

export default ModalAtualizarAvatarCadastro
