import { useState } from 'react'
import Perfil from '../../middleware/perfil'
import menuOpcoes from '../../interfaces/propsMenuOpcoes'
import SubMenuOpcoes from '../SubMenuOpcoes'
import '../../styles/menuopcoes.css'
import Organizacao from '../../middleware/organizacao'

function MenuDashboard(props: menuOpcoes) {

    const [subMenu, setSubMenu] = useState(false)

    function exibirSubmenu() {
        setSubMenu(subMenu => !subMenu)
    }

    return (
        <div>
            <div id='menuOpcoes' onClick={exibirSubmenu}>
                {<img id='iconeMenu' src={props.icone} alt={props.alt}></img>}
                <p id='tituloMenu'>{props.titulo}</p>
            </div>
            <div id='submenuOpcoes'>
                <Perfil perfil={['master']}>
                    <>
                        {subMenu && <SubMenuOpcoes redirect='/dashboard' texto='Geral' />}
                        {subMenu && <SubMenuOpcoes redirect='/dashboard/financeiro' texto='Financeiro' />}
                        <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
                            <>
                                {subMenu && <SubMenuOpcoes redirect='/dashboard/processos-seletivos' texto='Processos Seletivos' />}
                                {subMenu && <SubMenuOpcoes redirect='/dashboard/candidatos' texto='Banco de Talentos' />}
                            </>
                        </Organizacao>
                    </>
                </Perfil>
                {subMenu && <SubMenuOpcoes redirect={'/painel'} texto='Meu Painel' />}
            </div>
        </div>
    )
}

export default MenuDashboard