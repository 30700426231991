import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles";
import NpsProcessoSeletivo from "../../components/ProcessoSeletivoNps";

function Nps() {

  useEffect(() => {
    document.title = "Nps Recrutamento | Gesttor"
  }, [])

  return (
    <DadosContainerDefault>
      <NpsProcessoSeletivo />
    </DadosContainerDefault>
  );
}

export default Nps;