import { useEffect } from "react";
import { DadosContainerDefault } from "../../components/Styles";
import DashboardCandidato from "../../components/DashboardCandidatos";

function DashboardCandidatos() {

  useEffect(() => {
    document.title = "Dashboard Candidatos | Gesttor"
  }, [])

  return (
    <DadosContainerDefault>
      <DashboardCandidato />
    </DadosContainerDefault>
  );
}

export default DashboardCandidatos;