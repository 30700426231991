import { useState, useEffect, FormEvent, useContext } from 'react'
import { ModalEstadoContext } from '../../contexts/ModalEstado'
import { listarContas } from '../../services/contas'
import { cadastrarIntegracao, atualizarIntegracao, cadastrarWebHook } from '../../services/asaas'
import { dadosIntegracaoAsaas } from '../../interfaces/asaas'
import { contasListaResposta } from '../../interfaces/contas'
import { Button, Loader } from '../Styles'
import { toast } from 'react-toastify'
import integracaoImg from '../../assets/img/integracao.png'
import '../../styles/modal.css'


function ModalIntegracaoAsaas ({ dados }: dadosIntegracaoAsaas ) {

    const { modalExibir, atualizarDados } = useContext(ModalEstadoContext)


    const [contasBancarias, setContasBancarias] = useState<contasListaResposta[]>([])
    const [contaPadrao, setContaPadrao] = useState(dados?.idContaPadrao || '')
    const [chave, setChave] = useState(dados?.chave || '')
    const [situacao, setSituacao] = useState(dados?.situacao || '')
    const [processando, setProcessando] = useState (false)


    const ocultarModal = () => modalExibir('')
    
    const cadastrarIntegracaoAsaas = async (e: FormEvent) => {
        
        e.preventDefault()
        setProcessando(true)

        try {

            const payload = {
                idContaPadrao: contaPadrao,
                chave
            }
            const resposta = await cadastrarIntegracao(payload)

            if(resposta.status === 201) {
                toast.success('Dados Salvos com Sucesso. Favor Complete a Integração')
                setProcessando(false)
                atualizarDados()
                modalExibir('')

                return
            }
            
        } catch (error) {
            
            toast.error('Falha ao Salvar os Dados')
            setProcessando(false)
            
            return
        }
    }

    const atualizarIntegracaoAsaas = async (e: FormEvent) => {
        
        e.preventDefault()
        setProcessando(true)

        try {

            const payload = {
                idContaPadrao: contaPadrao,
                chave,
                situacao
            }
            const resposta = await atualizarIntegracao(payload)

            if(resposta.status === 200) {
                toast.success('Dados Atualizados com Sucesso')
                setProcessando(false)
                atualizarDados()
                modalExibir('')

                return
            }
            
        } catch (error) {
            
            toast.error('Falha ao Salvar os Dados')
            setProcessando(false)
            
            return
        }
    }

    const habilitarWebHook = async () => {

        setProcessando(true)

        try {
            
            const resposta = await toast.promise(
                cadastrarWebHook(), {
                    pending: 'Processando a Solicitação',
                    error: 'Falha ao Habilitar o WebHook',
                    success: 'WebHook Habilitado com Sucesso'
                }
            )

            if(resposta.status === 200) {
                atualizarDados()
                setProcessando(false)
                modalExibir('')
                    
                return

            }

        } catch (error) {

            setProcessando(false)
            toast.error('Falha ao Habilitar o WebHook na sua Conta')

            return
            
        }



    }


    useEffect(() => {

        const buscarContas = async () => {

            try {

                const params = {
                    descricao: '',
                    situacao: 'ativa'
                }
                const resposta = await  listarContas(params)

                if(resposta.status === 200) {
                    return setContasBancarias(resposta.data)
                }

                
            } catch (error) {
                toast.error('Falha ao Listar suas Contas Bancárias')

                return
            }
        }

        buscarContas()

    }, [])


    return (
        <div id='fundoModal' className='fundoModalIntegracaoInter'>
            <section className='containerModalCentro'>
                <span id='novoContainerTitulo'>
                    <img id='adicionarModalIcone' src={integracaoImg} alt='' />
                    <p>{dados?.situacao === 'ativo' ? 'Refazer Integração' : 'Configurar Integracao'}</p>
                </span>
                <div id='formModalNovo'>
                    <form onSubmit={dados ? atualizarIntegracaoAsaas : cadastrarIntegracaoAsaas}>
                        {dados?.situacao !== 'Incompleto' && <section>
                            <span>
                                <label >Conta Padrão</label>
                                <select id="contaTransacao" value={contaPadrao} onChange={(e) => setContaPadrao(e.target.value)} required>
                                    <option value=""></option>
                                    {contasBancarias.map((conta) => (
                                        <option key={conta.id} value={conta.id}>{conta.descricao}</option>
                                    ))}
                                </select>
                            </span>
                            {dados && <span>
                                <label >Situacao</label>
                                <select id="contaTransacao" value={situacao} onChange={(e) => setSituacao(e.target.value)} required>
                                    <option value=""></option>
                                    <option value="Ativo">Ativo</option>
                                    <option value="Inativo">Inativo</option>
                                </select>
                            </span>}
                        </section>}
                        {dados?.situacao !== 'Incompleto' && <section>
                            <span>
                                <label htmlFor="chaveApiAsaas">Chave da Api</label>
                                <textarea
                                    id='chaveApiAsaas' 
                                    rows={6}
                                    value={chave}
                                    placeholder='Cole aqui a chave da sua api'
                                    onChange={(e) => setChave(e.target.value)}
                                    required/>
                            </span>
                        </section>}
                        {dados?.situacao === 'Incompleto' && <section>
                            <span>
                                <label htmlFor="webHooksAsaas">Habilitar o WebHook</label>
                                <input
                                    id='webHooksAsaas' 
                                    value={process.env.REACT_APP_API_GESTTOR}
                                    disabled/>
                            </span>
                        </section>}
                        {dados?.situacao !== 'Incompleto' && <span id="botoesContainer">
                            <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                            <Button type='submit' cor='salvar' isLoading={processando}>
                                {!processando && 'Enviar Dados'}
                                {processando && <Loader isLoading={processando}/>}
                            </Button>
                        </span>}
                    </form>
                    {dados?.situacao === 'Incompleto' && <span id="botoesContainer">
                            <Button type='button' cor='cancelar' isLoading={processando} onClick={ocultarModal}>Cancelar</Button>
                            <Button type='button' cor='salvar' isLoading={processando} onClick={habilitarWebHook}>
                                {!processando && 'Enviar Dados'}
                                {processando && <Loader isLoading={processando}/>}
                            </Button>
                    </span>}
                </div>
            </section>
        </div>
    )
}

export default ModalIntegracaoAsaas
