import { useEffect, useState } from "react";
import Perfil from "../../middleware/perfil";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, Line, PieChart, Cell, Pie, AreaChart, Area } from 'recharts';
import { toast } from "react-toastify";
import { processoSeletivoPrazoAnual, processoSeletivoPrazoAoMes, processosRealizadosMes, quantidadeProcessosNps, situacaoProcessoSeletivo, tempoMedioExecucao } from "../../services/dashboardProcessoSeletivo";
import { processoSeletivoRealizadoNoMes, processoSeletivoNps, situacaoProcesso } from "../../interfaces/dashboardProcessoSeletivo";
import GraficoComponent from "../GraficoComponent";
import "./dashboardProcessoSeletivo.css"
import Organizacao from "../../middleware/organizacao";
import { MdPendingActions, MdOutlineSentimentVerySatisfied, MdOutlineReplay } from "react-icons/md";
import { BsMegaphone } from "react-icons/bs";
import { TbClockExclamation } from "react-icons/tb";
import LoadingCirculo from "../LoadingCirculo";

function DashboardProcessoSeletivo() {
  const [graficoProcessosRealizadosMes, setGraficoProcessosRealizadosMes] = useState<processoSeletivoRealizadoNoMes[]>([])
  const [processoSeletivoTempoMedioExecucao, setProcessoSeletivoTempoMedioExecucao] = useState<any[]>([])
  const [processoSeletivoPorPrazoMensal, setProcessoSeletivoPorPrazoMensal] = useState([
    { name: 'No prazo', value: 0 },
    { name: 'Fora do prazo', value: 0 }
  ]);
  const [processoSeletivoPorPrazoAnual, setProcessoSeletivoPorPrazoAnual] = useState([
    { name: 'No prazo', value: 0 },
    { name: 'Fora do prazo', value: 0 }
  ]);
  const [processoSeletivoNps, setProcessoSeletivoNps] = useState<processoSeletivoNps[]>([]);
  const [processoSeletivoSituacao, setProcessoSeletivoSituacao] = useState<situacaoProcesso[]>([
    { processos: 0, name: "nps" },
    { processos: 0, name: "recrutando" },
    { processos: 0, name: "aguardandoInscritos" },
    { processos: 0, name: "repescagem" },
    { processos: 0, name: "atrasado" },
  ]);
  const [carregando, setCarregando] = useState(false)

  useEffect(() => {
    const processosRealizados = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          processosRealizadosMes(),
          {
            error: 'Falha ao Processar Solicitação de Listagem dos Processos Realizados'
          }
        )

        const mesesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

        const dadosFormatados = resposta.data.map((processo: any) => ({
          name: `${mesesNomes[processo.month - 1]}/${processo.year}`,
          processos: processo.total,
        }));

        setGraficoProcessosRealizadosMes(dadosFormatados)
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar o Grafico de Processos Realizados no Mês")
      }
    }
    processosRealizados()
  }, [])

  useEffect(() => {
    const processosTempoMedioExecucao = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          tempoMedioExecucao(),
          {
            error: 'Falha ao Processar Solicitação de Listagem do tempo Médio de Execução'
          }
        )

        const mesesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

        const dadosFormatados = resposta.data.map((processo: any) => ({
          name: `${mesesNomes[processo.month - 1]}/${processo.year}`,
          processos: processo ? parseFloat(processo.media_duracao) : 0,
        }));

        setProcessoSeletivoTempoMedioExecucao(dadosFormatados)
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar o Tempo Médio de Execução dos Processos no Mês")
      }
    };

    processosTempoMedioExecucao()
  }, [])

  useEffect(() => {
    const processoSeletivoPorPrazo = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          processoSeletivoPrazoAoMes(),
          {
            error: 'Falha ao Processar Solicitação de Listagem dos Prazos do Processo por Mês'
          }
        );

        const dadosFormatados = [
          { name: 'No prazo', value: resposta.data[0].noPrazo },
          { name: 'Fora do prazo', value: resposta.data[0].foraPrazo },
        ];

        setProcessoSeletivoPorPrazoMensal(dadosFormatados)
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar os Processos Mensais no Prazo e fora do Prazo")
      }
    };

    processoSeletivoPorPrazo()
  }, [])

  useEffect(() => {
    const processoSeletivoPorPrazo = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          processoSeletivoPrazoAnual(),
          {
            error: 'Falha ao Processar Solicitação de Listagem dos Prazos do Processo por Ano'
          }
        );

        const dadosFormatados = [
          { name: 'No prazo', value: resposta.data[0].noPrazo },
          { name: 'Fora do prazo', value: resposta.data[0].foraPrazo },
        ];

        setProcessoSeletivoPorPrazoAnual(dadosFormatados)
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar os Processos Anual no Prazo e fora do Prazo")
      }
    };

    processoSeletivoPorPrazo()
  }, [])

  useEffect(() => {
    const quantidadeProcessosSeletivoNps = async () => {
      try {
        setCarregando(true)
        const mesesNomes = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        const resposta = await toast.promise(
          quantidadeProcessosNps(),
          {
            error: 'Falha ao Processar Solicitação de Listagem dos Nps do Processo Seletivo'
          }
        );

        const dadosFormatados = resposta.data.map((processo: any) => ({
          name: `${mesesNomes[processo.month - 1]}/${processo.year}`,
          aprovados: processo.integrouEquipe,
          reprovados: processo.naoIntegrouEquipe,
          month: processo.month,
          year: processo.year,
        }));

        setProcessoSeletivoNps(dadosFormatados)
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar os Processos Nps")
      }
    };

    quantidadeProcessosSeletivoNps()
  }, [])

  useEffect(() => {
    const situacaoProcessosSeletivos = async () => {
      try {
        setCarregando(true)
        const resposta = await toast.promise(
          situacaoProcessoSeletivo(),
          {
            error: 'Falha ao Processar Solicitação de Listagem da Situação dos Processos Seletivos'
          }
        );

        const dadosFormatados = resposta.data.map((processo: any) => ({
          name: processo.situacao,
          processos: processo.count,
        }));

        setProcessoSeletivoSituacao(dadosFormatados)
        setCarregando(false)
      } catch (error) {
        setCarregando(false)
        toast.error("Não foi possivel listar as situações dos Processos Seletivos")
      }
    }

    situacaoProcessosSeletivos()
  }, [])

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
      <Perfil perfil={['master']}>
        <div className='graficoDashboardContainer'>
          <div className='dadosDashboardDefault'>
            <div className='dashboardDefaultContent'>
              {carregando && <LoadingCirculo />}
              {!carregando && <h1>{processoSeletivoSituacao.find((situacao) => situacao.name === "Aguardando Inscritos")?.processos || "0"}</h1>}
              <p>Aguardando Inscritos</p>
            </div>
            <div>
              <MdPendingActions fontSize={"70px"} color="#FF6B6C" />
            </div>
          </div>
          <div className='dadosDashboardDefault'>
            <div className='dashboardDefaultContent'>
              {carregando && <LoadingCirculo />}
              {!carregando && <h1>{processoSeletivoSituacao.find((situacao) => situacao.name === "Recrutando")?.processos || "0"}</h1>}
              <p>Recrutando</p>
            </div>
            <div>
              <BsMegaphone fontSize={"70px"} color="#FF6B6C" />
            </div>
          </div>
          <div className='dadosDashboardDefault'>
            <div className='dashboardDefaultContent'>
              {carregando && <LoadingCirculo />}
              {!carregando && <h1>{processoSeletivoSituacao.find((situacao) => situacao.name === "nps")?.processos || "0"}</h1>}
              <p>Nps</p>
            </div>
            <div>
              <MdOutlineSentimentVerySatisfied fontSize={"70px"} color="#FF6B6C" />
            </div>
          </div>
          <div className='dadosDashboardDefault'>
            <div className='dashboardDefaultContent'>
              {carregando && <LoadingCirculo />}
              {!carregando && <h1>{processoSeletivoSituacao.find((situacao) => situacao.name === "Repescagem")?.processos || "0"}</h1>}
              <p>Repescagem</p>
            </div>
            <div>
              <MdOutlineReplay fontSize={"70px"} color="#FF6B6C" />
            </div>
          </div>
          <div className='dadosDashboardDefault'>
            <div className='dashboardDefaultContent'>
              {carregando && <LoadingCirculo />}
              {!carregando && <h1>{processoSeletivoSituacao.find((situacao) => situacao.name === "Atrasado")?.processos || "0"}</h1>}
              <p>Atrasado</p>
            </div>
            <div>
              <TbClockExclamation fontSize={"70px"} color="#FF6B6C" />
            </div>
          </div>
          <div className='graficoDashboardDefault'>
            <GraficoComponent title={"Processos Realizados Por Mês"} col={"grafico-col-1"}>
              {carregando ?
                <LoadingCirculo /> : <BarChart data={graficoProcessosRealizadosMes}>
                  <Bar dataKey="processos" fill="#25AE88" />
                  <Tooltip />
                  <CartesianGrid strokeDasharray="3 3" vertical={false} className="grafico-col-2" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Legend />
                  <Line type="monotone" dataKey="processos" stroke="#82ca9d" />
                </BarChart>
              }
            </GraficoComponent>
            <GraficoComponent title={"Tempo Médio de Execução dos Processos no Mês"} col={"grafico-col-2"}>
              {carregando ? <LoadingCirculo /> : <AreaChart
                width={500}
                height={400}
                data={processoSeletivoTempoMedioExecucao}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="processos" stroke="#8884d8" fill="#8884d8" />
              </AreaChart>
              }
            </GraficoComponent>
            <GraficoComponent title={"Dentro e fora do prazo Mensal"} col={"grafico-col-3"}>
              {carregando ? <LoadingCirculo /> : <PieChart width={400} height={400}>
                <Pie
                  data={processoSeletivoPorPrazoMensal}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  <Cell fill={"#25AE88"} />
                  <Cell fill={'#E21B1B'} />
                </Pie>
                <Tooltip />
              </PieChart>
              }
            </GraficoComponent>
            <GraficoComponent title={"Dentro e fora do prazo Anual"} col={"grafico-col-4"}>
              {carregando ? <LoadingCirculo /> : <PieChart width={400} height={400}>
                <Pie
                  data={processoSeletivoPorPrazoAnual}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  <Cell fill={"#25AE88"} />
                  <Cell fill={'#E21B1B'} />
                </Pie>
                <Tooltip />
              </PieChart>
              }
            </GraficoComponent>
            <GraficoComponent title={"Processo Seletivo Nps"} col={"grafico-col-5"}>
              {carregando ? <LoadingCirculo /> : <BarChart
                width={500}
                height={300}
                data={processoSeletivoNps}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="aprovados" stackId="a" fill="#25AE88" />
                <Bar dataKey="reprovados" stackId="a" fill="#E21B1B" />
              </BarChart>
              }
            </GraficoComponent>
          </div>
        </div>
      </Perfil>
    </Organizacao>
  );
}

export default DashboardProcessoSeletivo;
