import Organizacao from "../../middleware/organizacao"
import Perfil from "../../middleware/perfil"
import FiltrosDefault from "../FiltrosDefault"
import LoadingBarra from "../LoadingBarra"
import NenhumResultadoEncontrado from "../NenhumResultadoEncontrato"
import { FormEvent, useContext, useEffect, useState } from "react"
import { listarProcessoSeletivo } from "../../services/processosSeletivo"
import { ListaDeProcessoSeletivo } from "../../interfaces/processoSeletivo"
import DivSituacao from "../DivSituacao"
import { ModalEstadoContext } from "../../contexts/ModalEstado"
import ModalProcessoSeletivo from "../ModalProcessoSeletivo"
import { useLocation, useNavigate } from "react-router-dom"
import { criptografarDado } from "../../helpers"
import { toast } from "react-toastify"

function ProcessoSeletivo() {
  const { modal, modalExibir, atualizarInformacoes } = useContext(ModalEstadoContext)
  const navigate = useNavigate();

  const [processando, setProcessando] = useState(false)
  const [nenhumResultado, setNenhumResultado] = useState(false)

  const [processosSeletivos, setProcessosSeletivos] = useState<ListaDeProcessoSeletivo[]>([])
  const [processosSeletivosFiltrado, setProcessosSeletivosFiltrado] = useState<ListaDeProcessoSeletivo[]>([])
  const [situacaoProcesso, setSituacaoProcesso] = useState("")
  const [termo, setTermo] = useState("")

  const location = useLocation()

  const { search } = location

  const paramsUrl = new URLSearchParams(search)
  const meusProcessos = paramsUrl.get('meus-processos') || false

  const filtrarProcesso = (e?: FormEvent) => {
    e?.preventDefault();
    setNenhumResultado(false)

    const dadosFiltrados = processosSeletivos.filter(processo => {
      const filtroSituacao = situacaoProcesso === '' || processo.situacao.toString() === situacaoProcesso;
      const filtroTermo = termo === '' || processo.cadastro.nomeFantasia.toLowerCase().includes(termo.toLowerCase());
      return filtroSituacao && filtroTermo;
    });

    if (dadosFiltrados.length === 0) {
      setNenhumResultado(true)
    }

    setProcessosSeletivosFiltrado(dadosFiltrados);
  }

  const AbrirModalVisualizarProcessoSeletivo = async (processo: ListaDeProcessoSeletivo) => {
    let idProcessoCriptografado = criptografarDado(processo.id.toString())
    return navigate(`/processo-seletivo/${idProcessoCriptografado}`)
  }

  const abrirModalCriarProcessoSeletivo = () => {
    modalExibir("CriarProcessoSeletivo")
  }

  useEffect(() => {
    const listarProcesso = async () => {
      setProcessando(true)
      setNenhumResultado(false)

      try {
        const usuarioGesttor = localStorage.getItem("usuarioGesttor")
        const processoSeletivoListado = await listarProcessoSeletivo()

        if (meusProcessos && processoSeletivoListado) {

          const processoFiltrado = processoSeletivoListado.data.filter((processo: any) => processo.idResponsavel === usuarioGesttor && processo.situacao !== "nps")

          setProcessosSeletivos(processoFiltrado)
          setProcessosSeletivosFiltrado(processoFiltrado)
        } else if (!meusProcessos && processoSeletivoListado) {

          setProcessosSeletivos(processoSeletivoListado.data)
          setProcessosSeletivosFiltrado(processoSeletivoListado.data)
        }
        setProcessando(false)

      } catch (error) {
        toast.error('Falha ao Listar Processos Seletivos!')
        setNenhumResultado(true)
        setProcessando(false)
      }
    }
    listarProcesso()
  }, [meusProcessos, atualizarInformacoes])

  return (
    <Organizacao organizacao={[`${process.env.REACT_APP_ORGANIZACAO_HG}`]}>
      <Perfil perfil={['master', 'administrativo', 'atendimento', 'consultor']}>
        <div id='paginaContainer'>
          <FiltrosDefault termo={termo} situacao={situacaoProcesso} placeholder='Pesquise pelo nome fantasia do Cliente' tipo={"Processo"} onChangeTermo={(e) => { setTermo(e.target.value) }} onChangeSituacao={(e) => { setSituacaoProcesso(e.target.value) }} onSubmit={(e) => { filtrarProcesso(e) }} acaoBtn={() => { abrirModalCriarProcessoSeletivo() }} listaSituacao={[{ nome: 'Todos', valor: '' }, { nome: 'Aguardando Aprovação', valor: 'Aguardando Aprovação' }, { nome: 'Aguardando Inscritos', valor: 'Aguardando Inscritos' }, { nome: 'Recrutando', valor: 'Recrutando' }, { nome: 'Atrasado', valor: 'Atrasado' }, { nome: 'Repescagem', valor: 'Repescagem' }, ...(meusProcessos !== "true" ? [{ nome: 'Nps', valor: 'nps' }] : []), { nome: 'Finalizado', valor: 'Finalizado' }]} genero='masculino' isLoading={processando} />
          {processando && <LoadingBarra />}
          {nenhumResultado && <NenhumResultadoEncontrado />}
          {modal.nome === "CriarProcessoSeletivo" && <ModalProcessoSeletivo />}
          <section id="fundoContainerPagina">
            <table id="tabelaListaContainer">
              <thead>
                <tr>
                  <th align='left'>Cliente</th>
                  <th align='left' className='hide-responsive'>Cargo</th>
                  <th align='center' className='hide-responsive'>Regime</th>
                  <th align='center' className='hide-responsive'>Responsável</th>
                  <th align='center' className='hide-responsive'>Prazo</th>
                  <th align='center'>Situação</th>
                </tr>
              </thead>
              <tbody>
                {processosSeletivosFiltrado.map((processo) => (
                  <tr key={processo.id} onClick={() => { AbrirModalVisualizarProcessoSeletivo(processo) }}>
                    <td className='primeiraColuna' align="left">{processo.cadastro.nomeFantasia}</td>
                    <td align='left' className='hide-responsive'>{processo.titulo}</td>
                    <td align='center' className='hide-responsive'>{processo.regimeContratacao}</td>
                    <td align='center' className='hide-responsive'>{processo.usuario.nome}</td>
                    <td align='center' className='hide-responsive'>{new Date(processo.previsaoTermino).toLocaleDateString("pt-br")}</td>
                    <td align='center' className='ladoDireitoContent'>
                      <DivSituacao texto={`${processo.situacao.charAt(0).toUpperCase()}${processo.situacao.substring(1)}`} cor={processo.situacao.toLocaleLowerCase().replace(/\s+/g, '')} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
        </div>
      </Perfil>
    </Organizacao>
  )
}

export default ProcessoSeletivo