import { FormEvent, useContext, useState } from "react";
import { Button, Loader } from "../Styles";
import { criptografarDado } from "../../helpers";
import { abrirRepescagem, atualizarProcessoNps, criarProcessoNps, finalizarProcessoSeletivo } from "../../services/processosSeletivo";
import { ModalEstadoContext } from "../../contexts/ModalEstado";
import { toast } from "react-toastify";
import { ModalProcessoNpsProps } from "../../interfaces/processoSeletivo";
import avatarDefault from "../../assets/img/avatarDefault.png";

function ModalProcessoNps({ processoSeletivoSelecionado }: ModalProcessoNpsProps) {

  const { modalExibir, atualizarDados } = useContext(ModalEstadoContext);
  const [processando, setProcessando] = useState(false)

  const [atendeuExpectativa, setAtendeuExpectativa] = useState<string>(processoSeletivoSelecionado.processoNps?.[0]?.atendeuExpectativa || "")
  const [verificarIntegracaoCandidato, setVerificarIntegracaoCandidato] = useState<string>(processoSeletivoSelecionado.processoNps?.[0]?.verificarIntegracaoCandidato || "")
  const [comentario, setComentario] = useState<string>(processoSeletivoSelecionado.processoNps?.[0]?.comentario || "")

  const [modalRepescagemVisivel, setModalRepescagemVisivel] = useState(false);
  const [motivoRepescagem, setMotivoRepescagem] = useState('');
  const [previsaoTermino, setPrevisaoTermino] = useState('');
  const [inscricaoTermino, setInscricaoTermino] = useState('');
  const dataAtual = new Date().toLocaleDateString("en-CA");

  const handleSubmitFormulario = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    try {
      const payload = {
        atendeuExpectativa,
        verificarIntegracaoCandidato,
        comentario
      }

      const idProcessoSeletivoCript = criptografarDado(processoSeletivoSelecionado.id.toString())

      await criarProcessoNps(payload, idProcessoSeletivoCript)

      const payloadFinalizarProcesso = {
        idResponsavel: processoSeletivoSelecionado?.idResponsavel,
        dataInicio: processoSeletivoSelecionado?.dataInicio,
        situacao: "Finalizado"
      };

      const resposta = await finalizarProcessoSeletivo(payloadFinalizarProcesso, idProcessoSeletivoCript);

      if (resposta.status === 200) {
        modalExibir("")
        toast.success("Formulário Nps Adicionado com sucesso.")
        atualizarDados()
        setProcessando(false)
      }
    } catch (error) {
      toast.error("Erro ao Tentar Enviar Processo Nps")
      setProcessando(false)
    }
  }

  const handleUpdateFormulario = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    try {
      const payload = {
        atendeuExpectativa,
        verificarIntegracaoCandidato,
        comentario
      }

      const idProcessoNpsCript = criptografarDado(processoSeletivoSelecionado.processoNps[0].id.toString())

      const resposta = await atualizarProcessoNps(payload, idProcessoNpsCript)

      if (resposta.status === 200) {
        atualizarDados()
        modalExibir("")
        toast.success("Formulário Nps Atualizado com sucesso.")
        setProcessando(false)
      }
    } catch (error) {
      toast.error("Erro ao Tentar Atualizar Processo Nps")
      setProcessando(false)
    }
  }

  const abrirModalRepescagem = () => {
    setModalRepescagemVisivel(true);
  };

  const fecharModalRepescagem = () => {
    setModalRepescagemVisivel(false);
    setMotivoRepescagem('');
    setPrevisaoTermino('');
  };

  const handleSubmitRepescagem = async (e: FormEvent) => {
    e.preventDefault()
    setProcessando(true)
    try {
      const payload = {
        motivoRepescagem,
        inscricaoTermino,
        previsaoTermino,
      }

      const idProcessoSeletivoCript = criptografarDado(processoSeletivoSelecionado.id.toString())

      const resposta = await abrirRepescagem(payload, idProcessoSeletivoCript)

      if (resposta.status === 200) {
        modalExibir("")
        toast.success("Repescagem aberta com sucesso!")
        atualizarDados()
        setProcessando(false)
      }
    } catch (error) {
      toast.error("Erro ao Tentar Abrir a Repescagem")
      setProcessando(false)
    }
  }

  return (
    <div id="fundoModal">
      <section
        className="containerModalCentro overflow-auto modalDividido"
      >
        <span className="container-left-side">
          <span>
            <h2 className="titulo-dados-modal">Dados da Vaga</h2>
          </span>
          <span className="detalhes-dados-vaga">
            <p>
              Nome do Recrutador:
            </p>
            <span>
              <input type="text" name="" id="" defaultValue={processoSeletivoSelecionado.usuario.nome} disabled />
            </span>
          </span>
          <span className="detalhes-dados-vaga">
            <p>
              Data de Início:
            </p>
            <span>
              <input type="date" defaultValue={processoSeletivoSelecionado.dataInicio ? new Date(processoSeletivoSelecionado.dataInicio).toISOString().split("T")[0] : ""} disabled />
            </span>
          </span>
          <span className="detalhes-dados-vaga">
            <p>
              Data de Término das Inscrições:
            </p>
            <span>
              <input type="date" defaultValue={processoSeletivoSelecionado.dataTermino ? new Date(processoSeletivoSelecionado.dataTermino).toISOString().split("T")[0] : ""} disabled />
            </span>
          </span>
          <span className="detalhes-dados-vaga">
            <p>
              Previsão de Término:
            </p>
            <span>
              <input type="date" defaultValue={processoSeletivoSelecionado.previsaoTermino ? new Date(processoSeletivoSelecionado.previsaoTermino).toISOString().split("T")[0] : ""} disabled />
            </span>
          </span>
          <span className="detalhes-dados-vaga">
            <p>
              Principais Atribuições:
            </p>
            <span className="dados-vaga">
              <textarea className="textAreaModal" rows={5} disabled>{processoSeletivoSelecionado.principaisAtribuicao}</textarea>
            </span>
          </span>
          <span className="detalhes-dados-vaga">
            <p>
              Descrição:
            </p>
            <span className="dados-vaga">
              <textarea className="textAreaModal" rows={5} defaultValue={processoSeletivoSelecionado.descricao ? processoSeletivoSelecionado.descricao : "Não possui descrição"} disabled></textarea>
            </span>
          </span>
          <span>
            <h3 className="titulo-dados-modal">
              Candidatos Aprovados
            </h3>
            <ul className="dados-candidatos-aprovados">
              {processoSeletivoSelecionado?.processoInscritos
                ?.filter((aprovados) => aprovados.etapaProcesso === "Aprovado")
                .map((aprovados) =>
                (
                  <li key={aprovados.id} className="lista-candidato-aprovado" > <img className="avatar-candidato-aprovado" src={aprovados.bancoTalento.avatar ? aprovados.bancoTalento.avatar : avatarDefault} alt="" />{aprovados.bancoTalento.nomeCompleto}</li>
                )
                )}
            </ul>
          </span>
        </span>
        <span className="container-right-side">
          <form onSubmit={processoSeletivoSelecionado.processoNps?.[0]?.id ? handleUpdateFormulario : handleSubmitFormulario}>
            <span>
              <h2 className="titulo-dados-modal">Pesquisa de Satisfação</h2>
            </span>
            <span className="container-modal-pergunta">
              <p>1. O candidato recomendado atendeu as expectativas da empresa?</p>
              <span>
                <select defaultValue={atendeuExpectativa} onChange={(e) => setAtendeuExpectativa(e.target.value)} required>
                  <option value=""></option>
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                  <option value="Parcial">Parcial</option>
                </select>
              </span>
            </span>
            <span className="container-modal-pergunta">
              <p>1. O candidato recomendado atendeu as expectativas da empresa?</p>
              <span>
                <select defaultValue={verificarIntegracaoCandidato} onChange={(e) => setVerificarIntegracaoCandidato(e.target.value)} required>
                  <option value=""></option>
                  <option value="Sim">Sim</option>
                  <option value="Não">Não</option>
                </select>
              </span>
            </span>
            <span className="container-modal-pergunta">
              <p>3. Sugestão, crítica ou elogio do cliente:</p>
              <span>
                <textarea name="" id="" cols={65} rows={7} className="textAreaModal"
                  defaultValue={comentario} onChange={(e) => setComentario(e.target.value)} maxLength={500}></textarea>
              </span>
            </span>
            <span className="botoesContainer botoes-modal">
              <Button type='button' cor='novo' isLoading={processando} onClick={abrirModalRepescagem}>Repescagem</Button>
              <Button type='button' cor='cancelar' isLoading={processando} onClick={() => modalExibir("")}>Cancelar</Button>
              <Button type='submit' cor='enviar' isLoading={processando}>
                {!processando && processoSeletivoSelecionado.situacao === "Finalizado" ? 'Atualizar' : 'Enviar'}
                {processando && <Loader isLoading={processando} />}
              </Button>
            </span>
          </form>
        </span>
      </section>
      {modalRepescagemVisivel && (
        <div className='fundoModal'>
          <section className='containerModalCentro'>
            <span className="novoContainerTitulo">
              <h1>Repescagem</h1>
            </span>
            <form className='formModalNovo' onSubmit={handleSubmitRepescagem}>
              <section>
                <span>
                  <label htmlFor="">Motivo da Repescagem</label>
                  <textarea rows={3} className='areaTexto' onChange={(e: any) => setMotivoRepescagem(e.target.value)} required></textarea>
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Término das Inscrições</label>
                  <input type="date"
                    value={inscricaoTermino}
                    onChange={(e) => setInscricaoTermino(e.target.value)}
                    required
                    min={dataAtual}
                  />
                </span>
              </section>
              <section>
                <span>
                  <label htmlFor="">Previsão de Término</label>
                  <input type="date"
                    onChange={(e) => setPrevisaoTermino(e.target.value)}
                    required
                    min={inscricaoTermino}
                  />
                </span>
              </section>
              <span className="botoesContainer">
                <Button type='button' cor='cancelar' isLoading={false} onClick={fecharModalRepescagem}>Voltar</Button>
                <Button type='submit' cor='enviar' isLoading={false}>Salvar</Button>
              </span>
            </form>
          </section>
        </div>
      )}
    </div >
  );
}

export default ModalProcessoNps;