import BASEAPI from "../api/config"

export function curriculoCadastradoMensal () {
  return BASEAPI.get('/dashboard/candidatos/novosCandidatos')
}

export function curriculoAnaliseDisc () {
  return BASEAPI.get('/dashboard/candidatos/analiseDisc')
}

export function curriculosPorEstados () {
  return BASEAPI.get('/dashboard/candidatos/curriculosPorEstado')
}

export function curriculosPorCidades () {
  return BASEAPI.get('/dashboard/candidatos/curriculosPorCidade')
}

export function curriculosCompletosIncompletos () {
  return BASEAPI.get('/dashboard/candidatos/curriculosCadastradosCompletosIncompletos')
}

export function curriculoEstatisticas () {
  return BASEAPI.get('/dashboard/candidatos/estatisticas')
}