import BASEAPI from '../api/config'
import { cadastrarAsaasPayload, atualiAsaasPayload} from '../../interfaces/asaas'

export function buscarIntegracao () {
    return BASEAPI.get('/asaas')
}

export function cadastrarIntegracao (payload: cadastrarAsaasPayload) {
    return BASEAPI.post ('/asaas', payload)
}

export function atualizarIntegracao (payload: atualiAsaasPayload) {
    return BASEAPI.patch('/asaas', payload)
}

export function cadastrarWebHook () {
    return BASEAPI.put('/asaas')
}