import BASEAPI from "../api/config"

export function processosRealizadosMes() {
  return BASEAPI.get('/dashboard/processoSeletivo/realizados')
}

export function tempoMedioExecucao() {
  return BASEAPI.get('/dashboard/processoSeletivo/tempoMedioExecucao')
}

export function processoSeletivoPrazoAoMes() {
  return BASEAPI.get('/dashboard/processoSeletivo/processosPorPrazo')
}

export function processoSeletivoPrazoAnual() {
  return BASEAPI.get('/dashboard/processoSeletivo/processosPorPrazoAno')
}

export function quantidadeProcessosNps() {
  return BASEAPI.get('/dashboard/processoSeletivo/quantidadeProcessosNps')
}

export function situacaoProcessoSeletivo() {
  return BASEAPI.get('/dashboard/processoSeletivo/situacao')
}